<template>
  <div class="m-authorization">
    <form class="form" @submit.prevent="auth">
      <div class="m-authorization__phone is-no-error-massage">
        <span v-if="showNote" class="m-authorization__phone-note">
          {{ $t('Phone number note') }}
        </span>
        <SfInput
          ref="input"
          v-model="phone"
          v-mask="'+38 ### ### ## ##'"
          type="tel"
          name="phone"
          data-transaction-name="Auth - Phone - Field"
          :label="$t('Phone number')"
          :required="true"
          class="sf-input--filled o-order-step__input--width-100"
          @input="onPhoneInput"
        />
      </div>
      <ASmoothReflow>
        <div
          v-if="showRules && !payload && !isLoaded"
          class="m-authorization__rules"
        >
          <span>{{ $t('By continuing, you agree to the') }}</span>

          <span
            class="m-authorization__rules-link"
            @click="onUserAgreementClick"
          >
            {{ $t("rules") }}
          </span>
        </div>

        <div v-if="isLoaded && !payload && !isLoadingSMS">
          <SfButton
            v-if="showButton"
            @click="openCheckSms"
            type="button"
            data-transaction-name="Auth - Get SMS Code"
            class="sf-button--full-width form__submit"
          >
            {{ $t("Get code") }}
          </SfButton>
          <div
            v-if="showButton && showPin"
            type="button"
            data-transaction-name="Auth - Login with Pin"
            @click="openCheckPin"
            class="login-via-pin"
          >
            {{ $t('Login with PIN code') }}
          </div>
        </div>

        <div
          v-if="payload"
          class="m-authorization__check-code"
        >
          <OCheckCode
            :phone="payload.phone"
            :valid-status="payload.status"
            :flag="payload.flag"
            :is-visible="true"
            :client-state-save="payload.clientStateSave"
            :simple="true"
            :events="true"
            @check-code-open="$emit('check-code-open', $event)"
            @check-code-close="$emit('check-code-close')"
          />
        </div>
      </ASmoothReflow>

      <ALoadingSpinner
        v-show="isLoading || isLoadingSMS"
        :size="34"
        :weight="3"
        :is-absolute-position="true"
      />
    </form>
  </div>
</template>

<script>
import { Logger } from '@vue-storefront/core/lib/logger'
import { SfButton } from '@storefront-ui/vue';
import SfInput from 'theme/components/storefront-override/SfInput'
import { mapActions } from 'vuex';
import { mask } from 'vue-the-mask'
import debounce from 'lodash/debounce'
import { groups, sendToLogs } from 'theme/helpers/web-logging';
import { ModalList } from 'theme/store/ui/modals';
import ALoadingSpinner from 'theme/components/atoms/a-loading-spinner.vue';
import ASmoothReflow from 'theme/components/atoms/a-smooth-reflow.vue';
import OCheckCode from 'theme/components/organisms/o-check-code.vue';
import SmsBlock from 'theme/mixins/SmsBlock';

export default {
  name: 'MAuthorization',
  components: {
    OCheckCode,
    ASmoothReflow,
    ALoadingSpinner,
    SfInput,
    SfButton
  },
  mixins: [SmsBlock],
  props: {
    showNote: {
      type: Boolean,
      default: () => true
    },
    showRules: {
      type: Boolean,
      default: () => false
    },
    clientStateSave: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      phone: '+38 ',
      status: '',
      showPin: false,
      isLoading: false,
      isLoadingSMS: false,
      isLoaded: false,
      payload: null,
      type: null
    };
  },
  directives: {
    mask
  },
  computed: {
    showButton () {
      return this.phone.length === 17
    }
  },
  methods: {
    ...mapActions({
      validate: 'userInfo/validate',
      sendOtp: 'userInfo/sendOtp',
      spawnNotification: 'notification/spawnNotification',
      openModal: 'ui/openModal'
    }),
    onPhoneInput: debounce(function () {
      if (this.phone.length === 17) {
        this.showPin = false
        this.auth()

        return
      }

      this.isLoaded = false
      this.payload = null
    }, 300),
    onUserAgreementClick () {
      this.openModal({ name: ModalList.TermsAndConditionsUserAgreement })
    },
    async auth () {
      try {
        this.isLoaded = false
        this.isLoading = true
        this.payload = null
        this.$refs.input.$el.getElementsByTagName('input')[0].blur()
        const response = await this.validate(this.phone.split(' ').join(''))
        this.status = response.result.status
        this.showPin = this.status === 'pin'

        if (this.showPin) this.openCheckPin()
      } catch (error) {
        this.status = ''

        sendToLogs(
          groups.Auth,
          'mAuth:catch:error',
          { message: error.message }
        )
      } finally {
        this.isLoaded = true
        this.isLoading = false
      }
    },
    openCheckPin () {
      this.payload = {
        phone: this.phone,
        status: this.status,
        flag: 'pin',
        clientStateSave: this.clientStateSave
      }
    },
    async openCheckSms () {
      if (!this.checkCanSendSMS()) return

      try {
        this.isLoadingSMS = true
        const response = await this.sendOtp(this.phone.split(' ').join(''))
        if (response.result.status === 'success') {
          const data = {
            phone: this.phone,
            status: this.status,
            flag: 'sms',
            clientStateSave: this.clientStateSave
          }

          this.$emit('check-code-open', data)
        } else {
          this.onFailure({ result: this.$t('Unexpected error when sending a code by SMS') });
          this.$emit('check-code-close')
          sendToLogs(
            groups.Auth,
            'mAuth:openCheckSms:not:success',
            { status: response.result.status },
            response?.transparentId
          )
        }
      } catch (error) {
        this.$emit('check-code-close')
        Logger.error(error, 'sendOtp')();
        this.onFailure({ result: this.$t('Unexpected authorization error. Check your Network connection.') });
        sendToLogs(
          groups.Auth,
          'mAuth:openCheckSms:catch:error',
          { message: error.message }
        )
      } finally {
        this.isLoadingSMS = false
      }
    },
    onFailure (result) {
      this.spawnNotification({
        type: 'danger',
        message: this.$t(result.result),
        action1: { label: this.$t('OK') }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.m-authorization {
  &__rules {
    padding-top: var(--spacer-10);
    font-size: 13px;

    &-link {
      color: var(--orange);
      cursor: pointer;
    }
  }

  &__phone {
    position: relative;

    &-note {
      display: block;
      margin-bottom: var(--spacer-10);
    }
  }

  ::v-deep {
    .sf-input__wrapper {
      margin: 0
    }
  }

  .form {
    width: 100%;

    &__element {
      margin: 0;
      --input-background: var(--light-gray);
      --input-padding: var(--spacer-27) var(--spacer-15) var(--spacer-10);
      --input-font-line-height: var(--spacer-18);
      --input-border: none;
      --input-label-font-size: var(--font-size-12);
      --input-label-font-line-height: var(--font-size-15);
    }
    &__checkbox {
      margin: var(--spacer-xl) 0 var(--spacer-2xl) 0;
    }
    &__submit {
      margin: var(--spacer-24) 0 0 0;
    }
  }
}

.action-button {
  margin: var(--spacer-xl) 0;
}

.is-no-error-massage {
  ::v-deep .sf-input__error-message {
    display: none;
  }
}

.sf-button {
  margin-top: var(--spacer-20);
}

.login-via-pin {
  color: var(--orange);
  text-align: center;
  cursor: pointer;
  font-size: var(--font-size-14);
  margin-top: var(--spacer-10);

  @media (max-width: $mobile-max) {
    margin-top: var(--spacer-15);
    margin-bottom: var(--spacer-15);
  }
}

.pin-loader {
  margin: var(--spacer-40) auto
}
</style>
