<template>
  <component :is="tag">
    <slot />
  </component>
</template>

<script>
import smoothReflow from 'theme/mixins/SmoothReflow'
export default {
  name: 'ASmoothReflow',
  mixins: [smoothReflow],
  props: {
    tag: {
      type: String,
      default: 'div'
    },
    options: Object
  },
  mounted () {
    this.$smoothReflow(this.options)
  }
}
</script>
