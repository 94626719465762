<template>
  <div class="m-modal-terms-and-conditions">
    <SfModal
      :overlay="true"
      :visible="isVisible"
      :title="$t('Site terms of use')"
      :transition-modal="`none`"
      :transition-overlay="`none`"
      @close="closeModal"
      class="modal"
    >
      <SfHeading
        :title="$t('Site terms of use')"
        :level="3"
      />
      <div class="modal__content">
        <div v-html="pageContent" />
      </div>
    </SfModal>
  </div>
</template>

<script>
import { SfModal, SfHeading } from '@storefront-ui/vue';
import { SearchQuery } from 'storefront-query-builder';
import { quickSearchByQuery } from '@vue-storefront/core/lib/search';

export default {
  name: 'MTermsAndConditionsUserAgreement',
  components: {
    SfModal,
    SfHeading
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    modalData: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },
  data () {
    return {
      pageContent: ''
    }
  },
  methods: {
    closeModal () {
      this.$store.dispatch('ui/hideBottomNavigation', false)
      this.$emit('close', this.modalData.name)
    },

    /**
     * Get the content of the User Agreement CMS page
     */
    async fetchPageContent () {
      const query = new SearchQuery();
      query.applyFilter({ key: 'identifier', value: 'user-agreement' });

      const { items } = await quickSearchByQuery({
        query,
        entityType: 'cms_page'
      });

      this.pageContent = items[0]?.content || '';
    }
  },

  mounted () {
    this.fetchPageContent();
  }
}

</script>
<style lang="scss" scoped>
@import "~theme/css/breakpoints";

  .m-modal-terms-and-conditions {
    ::v-deep {
      .sf-modal__container {
        top: 78px;

        @include for-desktop {
          --modal-transform: translate3d(-50%, 0, 0);
          width: 970px;
          top: 66px;
          bottom: 66px;
        }
        .sf-heading {
          display: none;
          @include for-desktop {
            display: block;
            margin-bottom: 40px;
            h5 {
              font-size: var(--font-size-20);
            }
          }
          &__title {
            font-size: var(--font-size-20);
            line-height: 1;
            @include for-desktop {
              font-size: var(--font-size-32);
            }
          }
        }
      }
      .sf-modal__bar {
        padding: 0 10px;
        justify-content: space-between;
        > div:first-child {
          display: none;
        }
      }
      .sf-modal__content {
        font-size: var(--font-size-14);
      }
      .sf-overlay.sf-modal__overlay {
        z-index: var(--sf-overlay-terms-and-conditions);
      }
    }
  }
.modal {
  &__content {
    max-height: 100vh;
    @include for-desktop {
      max-height: 80vh;
    }
  }

  &__button {
    display: block;
    margin-top: var(--spacer-xl);
    margin-bottom: var(--spacer-xl);
  }
}

::v-deep {
  .sf-modal__container {
    padding: 0 10px;
    @include for-desktop {
      padding: 50px 5px 30px 50px;

      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 60px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 59.43%);
        position: absolute;
        bottom:0;
        left: 0;
      }
    }
    .sf-bar__title {
      font-size: var(--font-size-20);
    }
  }

  .sf-modal__close {
    top: 15px;
    @include for-desktop {
      top: 20px;
      right: 20px;
    }
  }

  .sf-modal__content {
    padding: 0;

    .items-list {
      margin: 0;
      padding-left: 25px;
    }

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--light-orange);

      &:hover {
        background-color: var(--orange);
      }
    }
  }

  .modal__content {
    padding-right: 15px;
    @include for-mobile {
      border-top: 2px solid var(--light-gray);
    }
    @include for-desktop {
      padding-right: 45px;
    }
    .title {
      font-weight: 500;
      font-size: var(--font-size-20);
    }

    .item-list {
      padding-bottom: var(--spacer-20);
    }
  }
}
</style>
