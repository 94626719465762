<template>
  <div class="m-cart-popup-promotion vso-styled-modal">
    <SfOModal
      transition-modal="bottom-top"
      :visible="isVisible"
      :title="$t('Promotion details')"
      :is-mobile-bar="false"
      class="m-cart-popup-promotion__modal"
      @close="closeModal"
    >
      <div>
        <p class="m-cart-popup-promotion__title">
          «{{ content.title }}»
        </p>
        <div class="m-cart-popup-promotion__text" v-html="content.description" />
      </div>
    </SfOModal>
  </div>
</template>

<script>
import SfOModal from 'theme/components/storefront-override/SfOModal';

export default {
  name: 'MCartPopupPromotion',
  components: { SfOModal },
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    modalData: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },
  computed: {
    content () {
      const title = this.modalData.payload?.title || ''
      const description = this.modalData.payload?.description || ''

      return {
        title,
        description
      };
    }
  },
  methods: {
    closeModal () {
      this.$emit('close', this.modalData.name)
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~theme/css/px2rem";
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/mixins";
@import "~theme/css/new-modals";

.m-cart-popup-promotion {
  &__modal {
    --modal-close-top: #{px2rem(13)};
    --modal-close-right: var(--spacer-20);
    --modal-width: #{px2rem(600)};
    --modal-left: calc(100% - var(--modal-width));
    --modal-right: initial;
    --modal-bottom: 0;
    --modal-top: auto;
    --modal-height: auto;
    --modal-transform: initial;

    @media (max-width: $mobile-max) {
      --modal-width: 100%;
    }

    ::v-deep {
      .bottom-top-leave-active,
      .bottom-top-enter-active {
        transition: .3s;
      }
      .bottom-top-enter {
        margin-bottom: -100%;
        opacity: 0!important;
      }
      .bottom-top-leave-to {
        margin-bottom: -100%;
        opacity: 0!important;
      }
    }
  }

  &__title {
    font-size: var(--font-size-16);
    font-weight: var(--font-medium);
    color: var(--black);
    text-align: left;
    margin: 0 0 24px;
  }

  &__text {
    font-size: var(--font-size-14);
    font-weight: var(--font-normal);
  }
}

</style>
