<template>
  <div class="m-date-picker">
    <date-picker
      v-model="dateValue"
      :type="type"
      :lang="lang"
      :range="range"
      inline
      @change="dateChange"
      :confirm="confirm"
      :confirm-text="$t('ApplyAction')"
      :cancel-text="$t('CancelAction')"
      :disabled-date="disabledDate"
    />
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/uk';
import 'vue2-datepicker/index.css';

export default {
  name: 'MDatePicker',
  components: {
    DatePicker
  },
  data () {
    return {
      dateValue: null,
      type: 'date',
      lang: {
        formatLocale: {
          firstDayOfWeek: 1
        },
        monthBeforeYear: false
      }
    }
  },
  props: {
    range: {
      type: Boolean,
      default: false
    },
    confirm: {
      type: Boolean,
      default: false
    },
    disabledDate: {
      type: [Function],
      default: () => {
        // handler placeholder
      }
    }
  },
  methods: {
    dateChange () {
      this.$emit('dateChange', this.dateValue)
    }
  }
}
</script>
