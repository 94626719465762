<template>
  <ASmoothReflow>
    <div class="m-auth" v-if="currentUser && isNewUser">
      <MRegister @close="$emit('close')" />
    </div>
    <div class="m-auth" v-else-if="checkCode">
      <OCheckCode
        :phone="checkCode.phone"
        :flag="checkCode.flag"
        :is-visible="true"
        :valid-status="checkCode.status"
        :client-state-save="checkCode.clientStateSave"
        @close="checkCodeClose"
      />
    </div>
    <div class="m-auth" v-else>
      <div class="auth-header">
        {{ $t('Login or register') }}
      </div>
      <p class="auth-summary">
        {{ $t('To quickly place and track orders, receive bonuses and save products, enter your account') }}
      </p>

      <div class="m-auth__form">
        <MAuthorization
          :show-note="false"
          :show-rules="true"
          @check-code-open="checkCodeOpen"
          @check-code-close="checkCodeClose"
        />
      </div>
    </div>
  </ASmoothReflow>
</template>

<script>
import MAuthorization from 'theme/components/molecules/m-authorization'
import MRegister from 'theme/components/molecules/m-register'
import ASmoothReflow from 'theme/components/atoms/a-smooth-reflow.vue';
import OCheckCode from 'theme/components/organisms/o-check-code.vue';

export default {
  name: 'MAuth',
  components: {
    OCheckCode,
    ASmoothReflow,
    MAuthorization,
    MRegister
  },
  props: {
    currentUser: {
      type: Object,
      required: true
    },
    isNewUser: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      checkCode: null
    }
  },
  methods: {
    checkCodeOpen (data) {
      this.checkCode = data
    },
    checkCodeClose () {
      this.checkCode = null
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.auth-header {
  font-size: var(--font-xl);
  font-weight: var(--font-medium);
  text-align: center;
  line-height: var(--font-size-25);
  margin-bottom: var(--spacer-15);

  @media (max-width: $mobile-max) {
    display: none;
  }
}

.auth-summary {
  font-size: var(--font-sm);
  line-height: var(--font-size-17);
  text-align: center;
  color: var(--dark-gray);

  @media (max-width: $mobile-max) {
    color: var(--black);
    font-size: var(--font-size-13);
  }
  margin: 0;
}

.m-auth {
  padding-top: var(--spacer-40);
  padding-bottom: var(--spacer-40);

  @media (max-width: $mobile-max) {
    padding: 0;
  }

  &__form {
    padding-top: var(--spacer-20);
    width: 100%;
  }

  ::v-deep {
    .sf-button,
    .react-code-input > *
    {
      width: 100%!important;
    }

    .check-code {
      height: auto!important;
    }
  }
}
</style>
