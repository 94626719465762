<template>
  <div class="m-modal-widget">
    <SfOModal
      :visible="isVisible"
      :is-bar-back="true"
      :is-bar-close="false"
      :persistent="true"
      @close="closeThisModal"
      @back="closeThisModal"
      :title="$t('Payment card authorization')"
    >
      <div class="widget-wrapper">
        <SfHeading
          :level="3"
          :title="$t('Payment card authorization')"
          class="widget-header"
        />
        <div id="widget-checkout" />
        <ALoadingSpinner
          key="spinner"
          class="widget-loader"
          v-if="loading"
          :is-absolute-position="true"
        />
      </div>
    </SfOModal>
  </div>
</template>

<script>
import SfOModal from 'theme/components/storefront-override/SfOModal';
import ALoadingSpinner from 'theme/components/atoms/a-loading-spinner'
import { SfHeading } from '@storefront-ui/vue';
import { ModalList } from 'theme/store/ui/modals'
import { mapActions } from 'vuex'

export default {
  name: 'MTranzzoWidget',
  components: {
    SfHeading,
    SfOModal,
    ALoadingSpinner
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    modalData: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },
  data () {
    return {
      loading: true
    }
  },
  watch: {
    isVisible (newValue, oldValue) {
      if (oldValue === newValue) return

      if (newValue) {
        this.loading = true
        this.addEventListeners()
      } else {
        this.removeEventListeners()
      }
    }
  },
  methods: {
    ...mapActions({
      closeModal: 'ui/closeModal'
    }),

    closeThisModal () {
      this.closeModal({ name: ModalList.TranzzoWidgetPopup });
    },
    onTranzzoWidgetInitHandler () {
      this.loading = false
    },
    onTranzzoWidgetInitErrorHandler (e) {
      console.error('Tranzzo widget initialization error: ', e.detail.message)
    },
    addEventListeners () {
      document.addEventListener('widget-init-ready', this.onTranzzoWidgetInitHandler)
      document.addEventListener('widget-init-error', this.onTranzzoWidgetInitErrorHandler)
    },
    removeEventListeners () {
      document.removeEventListener('widget-init-ready', this.onTranzzoWidgetInitHandler)
      document.removeEventListener('widget-init-error', this.onTranzzoWidgetInitErrorHandler)
    }
  },
  mounted () {
    this.addEventListeners()
  },
  beforeDestroy () {
    this.removeEventListeners()
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.m-modal-widget {
  .widget-wrapper {
    height: 400px;
  }

  ::v-deep {
    .sf-modal {
      --bar-padding: 0 var(--spacer-20);

      @media (min-width: $tablet-min) {
        --modal-width: 420px;
        --modal-height: auto;
        --modal-top: 50%;
        --modal-left: 50%;
        --modal-bottom: none;
        --modal-right: none;
        --modal-transform: translate3d(-50%, -50%, 0);
        --modal-content-padding: 0;
        --modal-close-left: var(--spaser-5);
        --modal-close-right: var(--spaser-5);
      }
    }

    .sf-bar {
      border-bottom: 2px solid var(--light-gray);

      div:nth-child(2) {
        flex-grow: 1;
        margin-left: var(--spacer-15);
      }

      @media (min-width: $tablet-min) {
        display: none;
      }
    }

    .sf-bar__title {
      font-size: var(--font-size-20);
      font-weight: var(--font-medium);
      line-height: var(--font-size-24);
      padding: var(--spacer-16) 0;
      white-space: nowrap;
    }

    .sf-modal__container {
      @media (max-width: $mobile-max) {
        height: 100%;
      }
    }

    .sf-modal__close {
      @media (max-width: $mobile-max) {
        display: none;
      }

      @media (min-width: $tablet-min) {
        display: inline-flex !important;
      }
    }

    .sf-modal__content {
      padding: var(--spacer-50) var(--spacer-10);
      font-family: var(--font-family-inter);

      @media (max-width: $mobile-max) {
        height: 100%;
        padding: var(--spacer-20) var(--spacer-10) var(--spacer-20);
      }

      .widget-header {
        margin-bottom: var(--spacer-20);

        @media (max-width: $mobile-max) {
          display: none;
        }
      }

      .sf-heading {
        padding: 0;

        &__title {
          font-size: var(--font-xl);
          font-weight: var(--font-medium);
        }
      }
    }
  }
}
</style>
