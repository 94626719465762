<template>
  <div class="m-tags">
    <div class="m-tags__list">
      <div
        v-for="(tag, i) in tags"
        :key="i"
        class="m-tags__link"
        :class="{
          'm-tags__link--active': mutableTagIndex === i,
          'm-tags__link--prev': mutableTagIndex === i + 1
        }"
        @click="setActiveCategory(i)"
      >
        <template>
          <span>{{ tag.name }}</span>
        </template>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'MTags',
  data () {
    return {
      mutableTagIndex: this.activeTagIndex
    }
  },
  props: {
    tags: {
      type: [Array, Object],
      default: () => []
    },
    activeTagIndex: {
      type: Number,
      default: 0
    }
  },
  methods: {
    setActiveCategory (index) {
      this.mutableTagIndex = index;
      this.$emit('setActiveCategory', index)
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~theme/css/breakpoints";

.m-tags {
  order: 3;
  overflow-y: hidden;
  scrollbar-width: none;
  margin: 0;
  background-color: #FDF0ED;
  width: 100%;
  border-radius: 10px;

  &::-webkit-scrollbar {
    display: none;
  }
}

.m-tags__list {
  border-radius: 10px;
  display: inline-flex;
  overflow-x: auto;
  background: rgba(255, 255, 255, 0.4);
  padding: var(--spacer-2xs);
  width: 100%;
  box-sizing: border-box;
}

.m-tags__link{
  position: relative;
  border-radius: 7px;
  color: var(--orange);
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  align-items: center;
  flex: 1 1 calc(100% / 3);
  justify-content: center;
  height: auto;
  padding: 12px;

  span {
    font-size: var(--font-size-14);
    font-weight: var(--font-medium);
    line-height: normal;
  }

  &:not(:last-child):not(.m-tags__link--active) {
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(0, -50%);
      width: 1px;
      height: 1.75rem;
      background: var(--green-light);
    }
  }

  &.sf-link{
    color: var(--orange);
  }

  &--active{
    background: var(--white);
    color: var(--black);
    cursor: default;
    margin-left: -1px;

    &:first-child {
      margin-left: 0;
    }

    &.sf-link{
      color: var(--black);
    }
  }

  &:hover{
    cursor: pointer;
    color: var(--primary-black);
  }
}
</style>
