<template>
  <div class="m-modal-change-pin">
    <SfOModal
      :visible="isVisible"
      :is-bar-back="true"
      :is-bar-close="false"
      @close="closeModal"
      @back="closeModal"
      :title="title"
    >
      <MChangePinCode />
    </SfOModal>
  </div>
</template>

<script>
import SfOModal from 'theme/components/storefront-override/SfOModal';
import MChangePinCode from 'theme/components/molecules/m-change-pin-code';
import { mapGetters } from 'vuex';

export default {
  name: 'MModalChangePin',
  components: {
    SfOModal,
    MChangePinCode
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    modalData: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },
  computed: {
    ...mapGetters({
      validationStatus: 'userInfo/validationStatus'
    }),
    title () {
      return this.validationStatus === 'pin' ? this.$t('Changing pin code') : this.$t('Adding pin code')
    }
  },
  methods: {
    closeModal () {
      this.$store.dispatch('ui/hideBottomNavigation', false)
      this.$emit('close', this.modalData.name)
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.m-modal-change-pin {
  ::v-deep {
    .sf-modal {
      --bar-padding: 0 var(--spacer-20);

      @media (min-width: $tablet-min) {
        --modal-width: 530px;
        --modal-top: 50%;
        --modal-left: 50%;
        --modal-bottom: none;
        --modal-right: none;
        --modal-transform: translate3d(-50%, -50%, 0);
        --modal-height: auto;
        --modal-content-padding: var(--spacer-50);
      }
    }

    .sf-bar {
      border-bottom: 2px solid var(--light-gray);

      div:nth-child(2) {
        flex-grow: 1 ;
        margin-left: var(--spacer-15);
      }

      @media (min-width: $tablet-min) {
        display: none;
      }
    }

    .sf-bar__title {
      font-size: var(--font-size-20);
      font-weight: var(--font-medium);
      line-height: var(--font-size-24);
      padding: var(--spacer-16) 0;
    }

    .sf-modal__container {
      @media (max-width: $mobile-max) {
        height: 100%;
      }
    }

    .sf-modal__close {
      @media (max-width: $mobile-max) {
        display: none;
      }

      @media (min-width: $tablet-min) {
        display: inline-flex!important;
      }
    }

    .sf-modal__content {
      padding: var(--spacer-50);
      font-family: var(--font-family-inter);

      @media (max-width: $mobile-max) {
        height: 100%;
        padding: var(--spacer-20) var(--spacer-10) var(--spacer-20);
      }

      .sf-heading {
        padding: 0;

        &__title {
          font-size: var(--font-xl);
          font-weight: var(--font-medium);
        }
      }

      .sf-button {
        width: 100%;
      }
    }

    .sf-input {
      width: 100%;
      --input-label-font-size: var(--font-size-12);

      input {
        border: none;
        background: var(--light-gray);
        --input-bar-display: none;

        &:focus {
          & ~ * {
            --input-label-top: 23%;
            --input-label-color: var(--dark-gray);
            --input-label-font-size: var(--font-size-12);
          }
        }
      }

      &__bar {
        position: relative;
        display: var(--input-bar-display, none);
      }
    }

    .sf-input__label:after {
      content: '';
    }
  }
}
</style>
