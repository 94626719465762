import { render, staticRenderFns } from "./m-tags.vue?vue&type=template&id=ba540fde&scoped=true"
import script from "./m-tags.vue?vue&type=script&lang=js"
export * from "./m-tags.vue?vue&type=script&lang=js"
import style0 from "./m-tags.vue?vue&type=style&index=0&id=ba540fde&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ba540fde",
  null
  
)

export default component.exports