import { mapState } from 'vuex';

export default {
  name: 'SmsBlock',
  computed: {
    ...mapState({
      canSendSms: state => state.ui.canSendSms,
      waitSmsSeconds: state => state.ui.waitSmsSeconds
    })
  },
  methods: {
    checkCanSendSMS () {
      if (this.canSendSms) return true

      this.$store.dispatch('notification/spawnNotification', {
        type: 'warning',
        message: this.$t(
          'You can send SMS again after {seconds} seconds',
          { seconds: this.waitSmsSeconds }
        ),
        action1: { label: this.$t('OK') }
      });

      return false
    }
  }
}
