<template>
  <div class="m-edit-group-wishlist">
    <SfModal
      :visible="isVisible"
      @close="closeModal"
      class="sf-modal--base"
      :title="$t(title)"
    >
      <template #close>
        <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="30" height="30" rx="5" fill="#F3F4F6" />
          <path d="M9 21 21 9l.7.7-12 12L9 21Z" fill="#333" />
          <path d="M21 21.7 9 9.7l.7-.7 12 12-.7.7Z" fill="#333" />
        </svg>
      </template>
      <div class="modal-content">
        <SfHeading
          :title="$t(title)"
          :level="3"
          class="m-edit-group-wishlist__title desktop-only"
        />
        <form
          @submit.prevent="saveChanges"
          class="wishlist-groups-form"
          v-if="!isDeleteConfirmation || isMobile"
        >
          <div class="wishlist-groups-form__content">
            <SfInput
              :label="$t('List name')"
              v-model.trim="wishlistName"
              data-transaction-name="Wishlist - Group Name - Field"
              class="sf-input--filled"
            />
          </div>
          <div class="wishlist-groups-form__actions">
            <SfButton
              class="sf-button--primary sf-button--full-width wishlist-groups-form__submit"
              type="submit"
              data-transaction-name="Wishlist - Save"
              :disabled="!isValidChanges"
            >
              {{ $t('Save') }}
            </SfButton>
            <span
              class="wishlist-groups-form__delete-confirmation"
              data-transaction-name="Wishlist - Delete"
              @click="isDeleteConfirmation = true"
            >
              {{ $t('Delete list') }}
            </span>
          </div>
        </form>
        <div class="wishlist-groups-form__overflow" v-if="isDeleteConfirmation && isMobile" />
        <form
          @submit.prevent="deleteList"
          class="wishlist-groups-form wishlist-groups-form--delete-confirmation"
          :class="{ 'wishlist-groups-form--mobile-confirmation': isMobile }"
          v-if="isDeleteConfirmation"
        >
          <div class="wishlist-groups-form__delete-confirmation-message">
            {{ isMobile ? $t('Delete list') + '?' : $t('Are you sure you want to delete the list') }}
          </div>
          <div class="sf-button--primary wishlist-groups-form__actions">
            <SfButton
              class="sf-button--primary wishlist-groups-form__delete"
              type="submit"
              data-transaction-name="Wishlist - Delete - Agree"
            >
              {{ $t('Delete') }}
            </SfButton>
            <SfButton
              class="sf-button--outline wishlist-groups-form__cancel"
              type="button"
              data-transaction-name="Wishlist - Delete - Decline"
              @click="isDeleteConfirmation = false"
            >
              {{ $t('Cancel') }}
            </SfButton>
          </div>
        </form>
      </div>
    </SfModal>
  </div>
</template>

<script>
import { SfHeading, SfModal, SfButton } from '@storefront-ui/vue'
import { mapActions } from 'vuex';
import DeviceType from 'theme/mixins/DeviceType'
import SfInput from 'theme/components/storefront-override/SfInput'

export default {
  name: 'MEditGroupWishlist',
  components: {
    SfModal,
    SfHeading,
    SfButton,
    SfInput
  },
  mixins: [
    DeviceType
  ],
  props: {
    isVisible: {
      type: Boolean,
      default: false,
      required: true
    },
    modalData: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },
  data () {
    return {
      wishlistName: '',
      wishlistId: 0,
      isDeleteConfirmation: false
    }
  },
  computed: {
    title () {
      return this.isDeleteConfirmation ? 'Delete list' : 'Editing list'
    },
    isValidChanges () {
      return this.wishlistName && !/[^0-9a-z_\-\sа-яїіёєґ]/i.test(this.wishlistName)
    }
  },
  methods: {
    ...mapActions({
      updateWishlistName: 'wishlist/updateWishlistName',
      deleteWishlist: 'wishlist/deleteWishlist'
    }),
    closeModal () {
      this.isDeleteConfirmation = false
      this.$emit('close', this.modalData.name)
    },
    async saveChanges () {
      await this.updateWishlistName({
        wishlistId: this.wishlistId,
        name: this.wishlistName
      })
      this.closeModal()
    },
    async deleteList () {
      await this.deleteWishlist(this.wishlistId)
      this.closeModal()
    }
  },
  watch: {
    'modalData.payload': {
      handler: function (wishlist) {
        if (wishlist) {
          this.wishlistName = wishlist.name
          this.wishlistId = wishlist.wishlist_id
        }
      },
      deep: true
    }
  },
  mounted () {
    this.wishlistName = this.modalData.payload?.name || ''
    this.wishlistId = this.modalData.payload?.wishlist_id || 0
  }
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.m-edit-group-wishlist {
  ::v-deep .sf-bar {
    > div:first-child {
      display: none;
    }
    --bar-font-size: var(--font-size-20);
    --bar-font-line-height: var(--font-xl);
    --bar-font-weight: var(--font-medium);
    --bar-background: var(--white);
    --bar-padding: var(--spacer-20) 17px;
    border-bottom: 1px solid var(--light-gray);
    text-align: left;
    color: var(--black)
  }

  &__title {
    ::v-deep .sf-heading__title--h3 {
      --heading-title-color: var(--black);
      --heading-title-font-weight: var(--font-medium);
      --heading-title-font-size: var(--font-xl);
      --heading-title-font-line-height: var(--font-xl);
    }
  }
}

.wishlist-groups-form {
  &__content {
    position: relative;

    @include for-desktop {
      margin: var(--spacer-20) 0;
      padding: var(--spacer-20) 0;
      border: 1px solid var(--light-gray);
      border-left: none;
      border-right: none;
    }

    ::v-deep .sf-input {
      &__wrapper {
        margin: 0;
      }

      &__error-message {
        display: none;
      }
    }
  }

  &__actions {
    text-align: center;
    position: absolute;
    bottom: var(--spacer-20);
    left: var(--spacer-20);
    width: calc(100% - var(--spacer-20) * 2);

    @include for-desktop {
      position: relative;
      width: 100%;
      left: 0;
      bottom: auto;
    }
  }

  &__submit {

  }

  &__delete-confirmation {
    cursor: pointer;
    margin-top: var(--spacer-10);
    color: var(--orange);
    font-size: var(--font-size-14);
    font-weight: var(--font-normal);
    display: inline-block;
  }

  &--delete-confirmation {
    .wishlist-groups-form {
      &__actions {
        display: flex;
        flex-direction: row;
        margin-top: var(--spacer-20);
        justify-content: center;
      }

      &__delete {
        margin-right: var(--spacer-10);
        width: 100%;
        max-width: 240px;
      }

      &__delete-confirmation-message {
        margin-top: var(--spacer-20);
        font-size: var(--font-size-14);
        font-weight: var(--font-normal);
        text-align: center;
      }
    }
  }

  &__overflow {
    background: var(--black);
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.4;
    z-index: 1;
  }

  &--mobile-confirmation {
    display: inline-block;
    position: absolute;
    z-index: 2;
    width: calc(100% - 40px);
    bottom: 23px;
    left: 20px;

    .wishlist-groups-form {
      &__actions {
        display: inline-block;
        position: relative;
        left: 0;
        width: 100%;
      }

      &__delete,
      &__cancel {
        width: 100%;
        margin: 0;
        max-width: 100%;
        --button-border-radius: 0 0 var(--spacer-5) var(--spacer-5);
        --button-background: var(--white);
        --button-color: var(--orange);
        --button-font-weight: normal;
        --button-font-size: var(--font-size-14);
        --button-font-line-height: var(--font-size-17);
      }

      &__cancel {
        margin-top: var(--spacer-5);
        border: none;
        --button-border-radius: var(--spacer-5);
        --button-color: var(--black);
      }

      &__delete-confirmation-message {
        font-size: var(--font-size-14);
        font-weight: var(--font-normal);
        text-align: center;
        padding: var(--spacer-15) 0 var(--spacer-17);
        background: var(--white);
        border-radius: var(--spacer-5) var(--spacer-5) 0 0;
        border-bottom: 1px solid var(--gray3);
      }
    }
  }
}
</style>
