<template>
  <SfCheckbox
    class="a-checkbox-toggle"
    v-model="currentValue"
    :name="name"
    :label="title"
    @change="onChange"
    @mousedown.native="memPosition"
    @mouseout.native="changeByDrag"
  />
</template>

<script>
import { SfCheckbox } from '@storefront-ui/vue';

export default {
  name: 'ACheckboxToggle',
  components: { SfCheckbox },
  props: {
    value: {
      type: Boolean,
      default: false,
      required: true
    },
    name: {
      type: String,
      default: '',
      required: false
    },
    title: {
      type: String,
      default: '',
      required: false
    }
  },
  data () {
    return {
      currentValue: this.value,
      mouseDownX: null
    };
  },
  watch: {
    value (val) {
      this.currentValue = val
    }
  },
  methods: {
    onChange (value) {
      this.$emit('input', value)
      this.mouseDownX = null
    },
    changeByDrag (event) {
      if (this.mouseDownX) {
        const directRight = event.clientX > this.mouseDownX
        const directLeft = event.clientX < this.mouseDownX

        if ((this.currentValue && directLeft) || (!this.currentValue && directRight)) {
          this.currentValue = !this.currentValue
          this.$emit('input', this.currentValue)
        }
      }
      this.mouseDownX = null
    },
    memPosition (event) {
      this.mouseDownX = event.clientX
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.a-checkbox-toggle {

  ::v-deep .sf-checkbox {
    &__checkmark {
      outline:none;
      position: relative;
      min-width: 32px;
      height: 20px;
      --checkbox-border-radius: 12px;
      --checkbox-border-color: var(--color-zircon);
      --checkbox-background: var(--color-zircon);
      // order: 1;

      .sf-icon {
        display: none;
      }

      &:before {
        content: '';
        display: inline-block;
        width: 14px;
        height: 14px;
        position: absolute;
        top: 2px;
        left: 2px;
        border-radius: 100%;
        background-color: var(--white);
        transition-duration: 0.5s
      }

      @include for-desktop {
        &:hover {
          --checkbox-border-color: var(--gray);
          --checkbox-background: var(--gray);
        }
      }

      &--is-active {
        --checkbox-border-color: var(--orange);
        --checkbox-background: var(--orange);

        &:before {
          left: auto;
          right: 2px;
          background-color: var(--white);
        }

        &:hover {
          --checkbox-border-color: var(--orange-hover);
          --checkbox-background: var(--orange-hover);
        }
      }
    }
    &__label {
      --checkbox-label-color: var( --black);
      --checkbox-font-family: var(--font-family-secondary);
      --checkbox-font-size: var(--font-size-13);
      --checkbox-font-weight: var(--font-normal);
    }
  }
}

</style>
