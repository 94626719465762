<template>
  <div
    v-click-outside="onDatePickerCancel"
    class="dob__container"
  >
    <SfInput
      :value="dob | getFormattedDobFromDateObject"
      name="dateOfBirthday"
      :label="$t('Date of birthday')"
      class="sf-input--filled dob__date"
      v-mask="'##.##.####'"
      @focus="showDatePicker"
      readonly
    >
      <template #icon>
        <span
          class="dob__date-icon"
          @click="showDatePicker"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
            <g clip-path="url(#a)">
              <path fill="#333" d="M2 11h20v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-9Zm15-8h4a1 1 0 0 1 1 1v5H2V4a1 1 0 0 1 1-1h4V1h2v2h6V1h2v2Z" />
            </g>
            <defs>
              <clipPath id="a"><path fill="#fff" d="M0 0h24v24H0z" /></clipPath>
            </defs>
          </svg>
        </span>
      </template>
    </SfInput>
    <MDatePicker
      v-if="isDatePickerShown"
      @dateChange="onDatePickerChange"
      @close="hideDatePicker"
      :disabled-date="notAfterToday"
    />
  </div>
</template>
<script>
import { mask } from 'vue-the-mask';
import MDatePicker from 'theme/components/molecules/m-date-picker';
import { clickOutside } from '@storefront-ui/vue/src/utilities/directives';
import SfInput from 'theme/components/storefront-override/SfInput.vue';

export default {
  components: { SfInput, MDatePicker },
  name: 'MDob',
  data () {
    return {
      isDatePickerShown: false
    };
  },
  props: {
    dob: {
      type: Date,
      default: null
    }
  },
  directives: {
    clickOutside,
    mask
  },
  methods: {
    showDatePicker () {
      this.isDatePickerShown = true
    },
    hideDatePicker () {
      this.isDatePickerShown = false
    },
    onDatePickerChange (date) {
      this.$emit('dobChange', date)
      this.hideDatePicker()
    },
    onDatePickerCancel () {
      if (!this.isDatePickerShown) return
      this.hideDatePicker()
    },
    notAfterToday (date) {
      const today = new Date()
      return date > today
    }
  },
  filters: {
    getFormattedDobFromDateObject (date) {
      if (!date) return ''

      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = String(date.getFullYear());

      return `${day}.${month}.${year}`;
    }
  }
}
</script>

<style lang="scss" scoped>
.dob__date {
  position: relative;

  ::v-deep {
    .sf-input__wrapper {
      position: relative;
    }
    input {
      cursor: pointer;
    }
  }
}

.dob__date-icon {
  position: absolute;
  right: 21px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  cursor: pointer;
}

::v-deep {
  .m-date-picker {
    position: absolute;
    z-index: 1;
  }
}
</style>
