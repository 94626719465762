<template>
  <div class="shp-modal" ref="modal">
    <SfOModal
      :visible="isVisible"
      @close="closeModal"
      class="shp-modal__main"
    >
      <template v-slot:modal-bar>
        <div class="shp-modal__bar">
          <span class="shp-modal__bar-title">{{ $t('Shipping address') }}</span>
        </div>
      </template>

      <ShippingAreaAsync
        :product-to-add="productToAdd"
        :is-draft-mode="isDraftMode"
        @close="closeModal"
      />
    </SfOModal>
  </div>
</template>
<script>
import SfOModal from 'theme/components/storefront-override/SfOModal';
import DeviceType from 'theme/mixins/DeviceType';
import ShippingAreaAsync from '$modules/shipping/components/shipping-area-async.vue';

export default {
  name: 'ShippingModal',
  components: { ShippingAreaAsync, SfOModal },
  mixins: [DeviceType],
  props: {
    isVisible: {
      type: Boolean,
      default: false,
      required: true
    },
    modalData: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },
  computed: {
    isDraftMode () {
      return this.modalData?.payload?.draftMode
    },
    productToAdd () {
      return this.modalData?.payload?.product || null
    }
  },
  methods: {
    closeModal () {
      if (!this.isDraftMode) {
        this.$store.dispatch('shipping-module/clearDraft')
      }

      this.$store.dispatch('ui/hideBottomNavigation', false)
      this.$emit('close', this.modalData.name);
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/mixins";

.shp-modal {
  &__main {
    --modal-width: 100%;
    --modal-height: 100%;
    --modal-content-padding: 0;

    @include for-desktop {
      --modal-width: 63.44rem;
    }

    ::v-deep {
      @include for-desktop {
        .sf-modal__container {
          max-height: 40.61rem;
        }
      }
      .sf-modal__content {
        height: 100%;
      }

      .a-loading-error, .a-loading-spinner {
        height: 100%;
      }
    }
  }

  ::v-deep {
    .sf-modal__close {
      z-index: 1001;
    }
  }

  &__bar {
    display: flex;
    align-items: center;
    height: 60px;
    @include header-title(22, 22);
    padding-left: 20px;
    border-bottom: 2px solid var(--light-gray);

    @include for-desktop {
      display: none;
    }
  }
}
</style>
