import { VueReCaptcha } from 'vue-recaptcha-v3';
import Vue from 'vue';
import config from 'config';

export default {
  name: 'ReCaptcha',
  methods: {
    initCaptcha () {
      if (this.$recaptchaInstance) return

      Vue.use(VueReCaptcha, {
        siteKey: config.captcha.siteKey,
        loaderOptions: {
          autoHideBadge: true
        }
      });
    }
  }
}
