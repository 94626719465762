<template>
  <div class="m-modal-cr">
    <SfOModal
      :visible="isVisible"
      :title="$t('Cancel order')"
      @close="closeModal"
      @back="closeModal"
    >
      <h1 class="m-modal-cr__title">{{$t('Cancel order')}}</h1>
      <span class="m-modal-cr__description">{{ $t('Choose cancel reason') }}:</span>

      <SfRadio
        v-for="reason in reasons"
        v-model="reasonId"
        :key="reason"
        :value="reason"
        name="shipping-method"
        class="m-modal-cr__radio"
        @input="changeReason"
      >
        <template #label>
          <div class="sf-radio__label m-modal-cr__radio-label">
            {{ $t(`Cancel reason ${reason}`) }}
          </div>
        </template>
      </SfRadio>

      <SfButton
        class="m-modal-cr__button sf-button--primary sf-button--full-width"
        :disabled="!reasonId"
        @click="handleCancelOrder"
      >
        {{ $t('Cancel order') }}
      </SfButton>

      <ALoadingSpinner
        :is-absolute-position="true"
        v-show="isLoading"
      />
    </SfOModal>
  </div>
</template>

<script>
import SfOModal from 'theme/components/storefront-override/SfOModal.vue';
import { mapActions, mapGetters } from 'vuex';
import { SfButton, SfRadio } from '@storefront-ui/vue';
import { Logger } from '@vue-storefront/core/lib/logger';
import i18n from '@vue-storefront/i18n';
import ALoadingSpinner from 'theme/components/atoms/a-loading-spinner.vue';

export default {
  name: 'MModalChangePin',
  components: {
    ALoadingSpinner,
    SfButton,
    SfRadio,
    SfOModal
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    modalData: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },
  data () {
    return {
      isLoading: false,
      reasonId: null
    }
  },
  computed: {
    ...mapGetters({
      validationStatus: 'userInfo/validationStatus'
    }),
    orderNumber () {
      return this.modalData?.payload?.orderNumber
    },
    reasons () {
      return [1, 2, 5, 6, 7, 8]
    }
  },
  methods: {
    ...mapActions({
      cancelOrder: 'user/cancelOrder',
      spawnNotification: 'notification/spawnNotification'
    }),
    closeModal () {
      this.$store.dispatch('ui/hideBottomNavigation', false)
      this.$emit('close', this.modalData.name)
    },
    changeReason (val) {
      this.reasonId = val
    },
    async handleCancelOrder () {
      try {
        this.isLoading = true
        const response = await this.cancelOrder({
          orderNumber: this.orderNumber,
          reasonId: this.reasonId
        })
        if (response.code === 200 && response.result === true) {
          this.onSuccess(this.$t('Order was canceled'));
        } else {
          this.onFailure({ result: this.$t('Order was not canceled') })
        }
        this.$bus.$emit('order-after-canceled');
        this.closeModal();
      } catch (error) {
        Logger.error(error, 'userHistory')()
        this.onFailure({ result: this.$t('Order was not canceled') })
      } finally {
        this.isLoading = false
      }
    },
    onSuccess (message) {
      this.spawnNotification({
        type: 'account',
        message: message
      });
    },
    onFailure (result) {
      this.spawnNotification({
        type: 'danger',
        message: i18n.t(result.result),
        action1: { label: i18n.t('OK') }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/mixins";

.m-modal-cr {
  &__button {
    margin-top: var(--spacer-10);
  }

  &__title {
    display: none;

    @media (min-width: $tablet-min) {
      display: block;
      @include header-title;
      margin-top: 0;
      margin-bottom: var(--spacer-20)
    }
  }

  &__radio {
    width: 100%;
    --radio-container-padding: var(--spacer-10) 0 var(--spacer-10) 0;
    --radio-background: transparent;

    &-label {
      display: inline-flex;
      align-items: center;
      font-size: var(--font-size-14);
    }

    ::v-deep {
      .sf-radio__content {
        display: flex;
      }
    }
  }

  &__description {
    display: block;
    font-size: var(--font-size-13);
    margin-bottom: var(--spacer-10);
  }

  ::v-deep {
    .sf-modal {
      --bar-padding: 0 var(--spacer-20);
      --modal-close-right: var(--spacer-20);
      --modal-close-top: var(--spacer-15);
      --bar-height: var(--spacer-60);

      @media (min-width: $tablet-min) {
        --modal-width: 530px;
        --modal-top: 50%;
        --modal-left: 50%;
        --modal-bottom: none;
        --modal-right: none;
        --modal-transform: translate3d(-50%, -50%, 0);
        --modal-height: auto;
        --modal-content-padding: var(--spacer-50);
      }
    }

    .sf-bar {
      border-bottom: 2px solid var(--light-gray);

      div:nth-child(2) {
        flex-grow: 1 ;
        margin-left: var(--spacer-15);
      }

      @media (min-width: $tablet-min) {
        display: none;
      }
    }

    .sf-modal__close {
      z-index: 1;
    }

    .sf-bar__title {
      @include header-title(22, 22);
      padding: var(--spacer-16) 0;
    }

    .sf-modal__container {
      @media (max-width: $mobile-max) {
        height: 100%;
      }
    }

    .sf-modal__content {
      padding: var(--spacer-50);
      font-family: var(--font-family-inter);
      position: relative;

      @media (max-width: $mobile-max) {
        height: 100%;
        padding: var(--spacer-20) var(--spacer-10) var(--spacer-20);
      }

      .sf-heading {
        padding: 0;

        &__title {
          font-size: var(--font-xl);
          font-weight: var(--font-medium);
        }
      }

      .sf-button {
        width: 100%;
      }
    }
  }
}
</style>
