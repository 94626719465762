<template>
  <div class="change-pin-inner-form">
    <SfHeading
      :level="3"
      :title="title"
      class="change-pin-header"
    />
    <MCheckSms
      v-if="inputSmsCode"
      :phone="formattedPhone"
      @onCompleteSMS="onCompleteSMS"
      @onEditPhone="edit"
    />
    <form v-else class="form" @submit.prevent="changePin">
      <div>
        <div class="type-new-pin">
          {{ $t('Type a new pin code') }}
        </div>
        <no-ssr>
          <CodeInput
            :loading="false"
            :field-width="81"
            :fields="4"
            class="pin-code"
            @change="onChangePin"
          />
        </no-ssr>
      </div>
      <SfButton
        :disabled="isSubmitDisable"
        class="sf-button--primary form__button"
        type="submit"
      >
        {{ buttonTitle }}
      </SfButton>
    </form>
  </div>
</template>

<script>
import {
  SfHeading,
  SfButton
} from '@storefront-ui/vue';
import { ModalList } from 'theme/store/ui/modals'
import { mask } from 'vue-the-mask'
import { mapActions, mapGetters } from 'vuex'
import MCheckSms from 'theme/components/molecules/m-check-sms'
import NoSSR from 'vue-no-ssr'
import { getFormattedPhone } from 'theme/helpers/text'

export default {
  name: 'MMChangePinCode',
  components: {
    SfHeading,
    SfButton,
    CodeInput: () => process.browser ? import('theme/components/molecules/m-pin-input') : null,
    MCheckSms,
    'no-ssr': NoSSR
  },
  directives: {
    mask
  },
  data () {
    return {
      inputSmsCode: true,
      isSubmitDisable: true,
      smsCode: '',
      pinCode: ''
    }
  },
  methods: {
    ...mapActions({
      openModal: 'ui/openModal',
      closeModal: 'ui/closeModal',
      changePinCode: 'userInfo/changePinCode',
      setValidStatus: 'userInfo/setValidationStatus',
      deleteAdditionalPhone: 'userInfo/deleteAdditionalPhone',
      spawnNotification: 'notification/spawnNotification'
    }),
    async changePin () {
      try {
        const response = await this.changePinCode({
          pin: this.pinCode,
          otp: this.smsCode
        })
        if (response.result?.status === 'success') {
          this.setValidStatus('pin')
          this.onSuccess(this.$t('PIN code is changed'))
          this.closeModal({ name: ModalList.ChangePinCode });
        } else {
          this.onFailure({ result: 'PIN code is not changed' });
          this.closeModal({ name: ModalList.ChangePinCode });
        }
      } catch (error) {
        this.onFailure({ result: 'PIN code is not changed' });
        this.closeModal({ name: ModalList.ChangePinCode });
      }
    },
    onCompleteSMS (value) {
      this.smsCode = value
      this.inputSmsCode = false
    },
    onChangePin (value) {
      if (value.length === 4) {
        this.pinCode = value
        this.isSubmitDisable = false
      } else {
        this.pinCode = ''
        this.isSubmitDisable = true
      }
    },
    edit () {
      this.closeModal({ name: ModalList.ChangePinCode });
      this.openModal({ name: ModalList.ChangePhoneAccount, payload: null })
    },
    onSuccess (message) {
      this.spawnNotification({
        type: 'success',
        message: message,
        action1: { label: this.$t('OK') }
      });
    },
    onFailure (result) {
      this.spawnNotification({
        type: 'danger',
        message: this.$t(result.result),
        action1: { label: this.$t('OK') }
      });
    }
  },
  computed: {
    ...mapGetters({
      validationStatus: 'userInfo/validationStatus'
    }),
    currentUser () {
      return this.$store.state.user.current
    },
    title () {
      return this.validationStatus === 'pin' ? this.$t('Changing pin code') : this.$t('Adding pin code')
    },
    buttonTitle () {
      return this.validationStatus === 'pin' ? this.$t('Change') : this.$t('Add')
    },
    formattedPhone () {
      return getFormattedPhone(this.currentUser.phone)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/third-party-components/vue-verification-code-input";

.change-pin-inner-form {
  display: flex;
  flex-direction: column;
  padding: 0 37px;

  @media (max-width: $mobile-max) {
    height: 100%;
    padding: 0;
  }

  .change-pin-header {
    margin-bottom: var(--spacer-20);

    @media (max-width: $mobile-max) {
      display: none;
    }
  }

  .form {
    display: flex;
    flex-direction: column;

    @media (max-width: $mobile-max) {
      height: 100%;
    }

    .type-new-pin {
      margin: 0 0 var(--spacer-15);
      text-align: center;

      @media (max-width: $mobile-max) {
        margin: var(--spacer-20) 0 var(--spacer-15);
        text-align: left;
      }
    }

    .form__button {
      font-size: var(--font-size-18);
      line-height: var(--font-size-24);

      @media (max-width: $mobile-max) {
        margin-top: auto;
      }
    }
  }
}
</style>
