<template>
  <div class="change-phone-inner-form">
    <SfHeading
      :level="3"
      :title="$t('Change account')"
      class="change-phone-header"
    />
    <MCheckSms
      v-if="inputSmsCode"
      :phone="addPhone"
      :custom-send-sms-action="true"
      @onCompleteSMS="onCompleteSMS"
      @onCustomSendSms="onCustomSendSms"
      @onEditPhone="edit"
      class="check-sms"
    />
    <form v-else class="form" @submit.prevent="phoneChange">
      <fieldset class="">
        <div class="select-from-list-text" v-if="phones && phoneList.length">
          {{ $t('Select from the list:') }}
        </div>
        <div
          class="sf-radio login-phone-radio"
          :class="{'sf-radio--is-active': isLoginPhoneChecked }"
          data-transaction-name="Change Account - Login Phone"
          @click="onLoginPhoneClick"
        >
          <label class="sf-radio__container">
            <div class="sf-radio__checkmark" />
            <div class="sf-radio__content">
              <div class="sf-radio__label">
                {{ getFormattedPhoneLocal(loginPhone) }}
              </div>
            </div>
          </label>
        </div>
        <SfRadio
          v-for="(phone, i) in phoneList"
          :key="i"
          :selected="addPhone"
          :value="getFormattedPhoneLocal(phone)"
          v-model="addPhone"
          @input="onPhoneSelect"
        >
          <template #label>
            <div class="sf-radio__label">
              {{ getFormattedPhoneLocal(phone) }}
            </div>
          </template>
        </SfRadio>
        <SfRadio
          @input="onNewNumberSelect"
          value="newNumber"
          :selected="isNewNumberSelected ? 'newNumber' : ''"
        >
          <template #label>
            <div class="sf-radio__label">
              {{ $t('Set new number') }}
            </div>
          </template>
        </SfRadio>
        <SfInput
          v-if="isNewNumberSelected"
          v-model="addPhone"
          name="addPhoneAccountOnModal"
          type="tel"
          :label="$t('Phone')"
          class="form__element"
          v-mask="'+38 ### ### ## ##'"
          :valid="!$v.addPhone.$error"
          :error-message="$t('This login is already registered')"
          data-transaction-name="Change Account - Phone"
          @input="validationPhone"
          @click="onPhoneClick"
        />
        <div v-else class="new-number-mock" />
      </fieldset>
      <SfButton
        :disabled="isSubmitDisable"
        data-transaction-name="Change Account - Submit"
        class="form__button"
        type="submit"
      >
        {{ $t('Apply') }}
      </SfButton>
    </form>
  </div>
</template>

<script>
import {
  SfHeading,
  SfButton,
  SfRadio
} from '@storefront-ui/vue';

import SfInput from 'theme/components/storefront-override/SfInput'

import { ModalList } from 'theme/store/ui/modals'
import { mask } from 'vue-the-mask'
import { mapActions } from 'vuex'
import { getFormattedPhone, getCleanedPhone } from 'theme/helpers/text';
import { uniqInAccount } from 'theme/helpers/validation';
import MCheckSms from 'theme/components/molecules/m-check-sms'

export default {
  name: 'MChangePhoneAccount',
  components: {
    SfHeading,
    SfInput,
    SfButton,
    SfRadio,
    MCheckSms
  },
  directives: {
    mask
  },
  data () {
    return {
      addPhone: '+38',
      isNewNumberSelected: false,
      inputSmsCode: false,
      updatePhoneToken: ''
    }
  },
  methods: {
    ...mapActions({
      closeModal: 'ui/closeModal',
      updatePhone: 'userInfo/updatePhone',
      updatePhoneConfirm: 'userInfo/updatePhoneConfirm',
      refreshUserData: 'user/me',
      spawnNotification: 'notification/spawnNotification'
    }),
    async phoneChange () {
      try {
        const response = await this.updatePhone({ newPhone: this.cleanedPhone })
        if (response.code === 200) {
          this.inputSmsCode = true
          this.updatePhoneToken = response.result
        } else {
          this.onFailure({ result: response.result?.errorMessage || this.$t('Unexpected error') });
          this.closeModal({ name: ModalList.ChangePhoneAccount });
        }
      } catch (error) {
        this.onFailure({ result: error });
        this.closeModal({ name: ModalList.ChangePhoneAccount });
      }
    },
    async phoneChangeConfirm (smsCode = '') {
      try {
        const response = await this.updatePhoneConfirm({
          code: smsCode,
          token: this.updatePhoneToken
        })
        if (response.result?.status === 'success') {
          this.onSuccess(this.$t('Phone is changed'))
          this.refreshUserData({ refresh: true, useCache: false })
          this.closeModal({ name: ModalList.ChangePhoneAccount });
        } else {
          this.onFailure({ result: 'Phone is not changed' });
          this.closeModal({ name: ModalList.ChangePhoneAccount });
        }
      } catch (error) {
        this.onFailure({ result: 'Phone is not changed' });
        this.closeModal({ name: ModalList.ChangePhoneAccount });
      }
    },
    onPhoneSelect () {
      this.isNewNumberSelected = false
    },
    onPhoneClick (event) {
      const el = event.srcElement;
      if (el.selectionStart < 3) {
        el.selectionStart = 3
      }
    },
    onNewNumberSelect () {
      this.addPhone = '+38'
      this.isNewNumberSelected = true
    },
    getFormattedPhoneLocal (phone) {
      return getFormattedPhone(phone)
    },
    edit () {
      this.inputSmsCode = false
    },
    onCompleteSMS (smsCode) {
      this.phoneChangeConfirm(smsCode)
    },
    onCustomSendSms () {
      this.phoneChange()
    },
    validationPhone () {
      this.$v.$touch()
    },
    onLoginPhoneClick () {
      this.addPhone = this.getFormattedPhoneLocal(this.loginPhone)
      this.isNewNumberSelected = false
    },
    onSuccess (message) {
      this.spawnNotification({
        type: 'success',
        message: message,
        action1: { label: this.$t('OK') }
      });
    },
    onFailure (result) {
      this.spawnNotification({
        type: 'danger',
        message: this.$t(result.result),
        action1: { label: this.$t('OK') }
      });
    }
  },
  computed: {
    isSubmitDisable () {
      return this.cleanedPhone.length !== 13 || this.cleanedPhone === this.loginPhone
    },
    currentUser () {
      return this.$store.state.user.current
    },
    phones () {
      return this.currentUser?.additional_phones || []
    },
    loginPhone () {
      return this.currentUser?.phone
    },
    phoneList () {
      return this.phones
    },
    cleanedPhone () {
      return getCleanedPhone(this.addPhone)
    },
    isLoginPhoneChecked () {
      return (this.cleanedPhone === this.loginPhone) & !this.isNewNumberSelected
    }
  },
  mounted () {
    this.addPhone = getFormattedPhone(this.loginPhone) || ''
  },
  validations () {
    return {
      addPhone: {
        uniqInAccount
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.change-phone-inner-form {
  display: flex;
  flex-direction: column;
  padding: 0 37px;

  @media (max-width: $mobile-max) {
    height: 100%;
    padding: 0;
  }

  .change-phone-header {
    font-size: var(--font-size-24);
    @media (max-width: $mobile-max) {
      display: none;
    }
  }

  .select-from-list-text {
    margin-top: var(--spacer-20);
  }

  .login-phone-radio {
    margin-top: var(--spacer-15);
  }

  .check-sms {
    @media (max-width: $mobile-max) {
      margin-top: var(--spacer-20);
    }
  }

  .sf-radio {
    background-color: transparent;
    margin-bottom: var(--spacer-10);

    &__container {
      padding: 0;
    }
    ::v-deep {
      .sf-radio {
        &__container {
          padding: 0;
        }
        &__content {
          display: flex;
          align-items: center;

          .sf-radio__label {
              font-size: var(--font-size-14);
              font-weight: var(--font-normal);
          }
        }
      }
    }
  }

  .new-number-mock {
    margin-top: var(--spacer-20);
  }
}
</style>
