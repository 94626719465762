<template>
  <SfModal :visible="isVisible" @close="closeModal" :title="isMobile || isTablet ? $t('Add card'): ''" class="m-modal-payment-card">
    <div class="modal-content">
      <form class="form">
        <SfHeading
          v-if="isDesktop"
          class="form-heading"
          :title="$t('Add card')"
          :level="3"
        />
        <SfInput
          v-model="formData.number"
          name="number"
          :label="$t('Card number')"
          :required="true"
          :valid="!$v.formData.number.$error"
          :error-message="$t('Field is required.')"
          class="sf-input--filled form__element"
        />
        <SfInput
          v-model="formData.expired"
          name="number"
          :label="$t('Expired')"
          :required="true"
          :valid="!$v.formData.expired.$error"
          :error-message="$t('Field is required.')"
          class="sf-input--filled form__element form__element--half form__element--half-odd"
        />
        <SfInput
          v-model="formData.cvv"
          name="number"
          :label="$t('cvv')"
          :required="true"
          :valid="!$v.formData.cvv.$error"
          :error-message="$t('Field is required.')"
          class="sf-input--filled form__element form__element--half form__element--half-even"
        />
        <div class="form__element">
          <SfCheckbox
            class="form__checkbox a-checkbox-toggle"
            v-model="formData.defaultCard"
            name="defaultCard"
            :label="$t('default card')"
          />
        </div>
        <SfButton @click.prevent="onAddCard" class="sf-button--full-width">
          {{ $t("Add") }}
        </SfButton>
      </form>
    </div>
  </SfModal>
</template>

<script>
import DeviceType from 'theme/mixins/DeviceType';
import { required } from 'vuelidate/lib/validators';
import { SfModal, SfHeading, SfButton, SfCheckbox } from '@storefront-ui/vue';
import SfInput from 'theme/components/storefront-override/SfInput'

export default {
  name: 'MModalPaymentCardAdd',
  components: { SfModal, SfHeading, SfInput, SfButton, SfCheckbox },
  mixins: [DeviceType],
  props: {
    isVisible: {
      type: Boolean,
      default: false,
      required: true
    },
    modalData: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },
  data () {
    return {
      formData: {
        number: '',
        expired: '',
        cvv: '',
        defaultCard: true
      }
    };
  },
  methods: {
    closeModal () {
      this.$emit('close', this.modalData.name)
    },
    onAddCard () {
      // this.$store.dispatch('module/addCardMethod', this.formData)
      console.log('addCard', this.formData)
      this.closeModal()
    },
    clearCardData () {
      this.formData = { number: '', expired: '', cvv: '', defaultCard: true }
    }
  },
  watch: {
    isVisible () {
      this.clearCardData()
    }
  },
  validations: {
    formData: {
      number: {
        required
      },
      expired: {
        required
      },
      cvv: {
        required
      },
      defaultCard: {
        required
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.m-modal-payment-card {
  @include for-desktop {
    --modal-width: 530px;
    --modal-content-padding: 50px;
  }
}

.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;

  .form {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

    &-heading {
      margin: -8px auto var(--spacer-15);
    }

    &__element {
      margin: 0 0 var(--spacer-10) 0;
      flex: 0 0 100%;

      &--half {
        justify-content: space-between;
        flex: 1 1 50%;

        &-odd {
          padding: 0 var(--spacer-5) 0 0;
        }

        &-even {
          padding: 0 0 0 var(--spacer-5);
        }
      }
    }

    &__checkbox {
      margin-bottom: var(--spacer-20);
    }
  }
}

.a-checkbox-toggle {
  margin-right: var(--spacer-sm);

  @include for-mobile {
    position: relative;
    margin: 0 var(--spacer-sm);
  }

  ::v-deep .sf-checkbox {
    &__checkmark {
      position: relative;
      min-width: 48px;
      height: 24px;
      --checkbox-border-radius: 12px;
      --checkbox-border-color: var(--color-zircon);
      --checkbox-background: var(--color-zircon);
      // order: 1;

      .sf-icon {
        display: none;
      }

      &:before {
        content: '';
        display: inline-block;
        width: var(--spacer-20);
        height: var(--spacer-20);
        position: absolute;
        top: 1px;
        left: 2px;
        border-radius: 12.5px;
        background-color: var(--white);
        transition-duration: 0.5s
      }

      &:hover {
        --checkbox-border-color: var(--gray);
        --checkbox-background: var(--gray-light);
      }

      &--is-active {
        --checkbox-border-color: var(--orange-light2);
        --checkbox-background: var(--orange-light2);

        &:before {
          left: auto;
          right: 2px;
          background-color: var(--orange);
        }

        &:hover {
          --checkbox-border-color: var(--orange-light);
          --checkbox-background: var(--orange-light);
        }
      }
    }
  }
}

</style>
