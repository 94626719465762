<template>
  <div class="m-add-child">
    <SfOModal
      :visible="isVisible"
      @close="closeModal"
      class="sf-modal--base sf-modal--md sf-modal--mq"
      :title="title"
    >
      <template #close>
        <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="30" height="30" rx="5" fill="#F3F4F6" />
          <path d="M9 21 21 9l.7.7-12 12L9 21Z" fill="#333" />
          <path d="M21 21.7 9 9.7l.7-.7 12 12-.7.7Z" fill="#333" />
        </svg>
      </template>
      <div class="modal-content">
        <div class="m-add-child__title desktop-only">
          {{ title }}
        </div>
        <form @submit.prevent="saveForm">
          <div class="row">
            <MTags
              :tags="genderTags"
              @setActiveCategory="selectGender"
              :active-tag-index="getSelectedGenderIndex"
            />
          </div>
          <div class="row">
            <SfInput
              v-model.trim="$v.name.$model"
              name="name"
              :label="$t('First name')"
              :valid="$v.name.isNameValid"
              :error-message="$t('Please use only letters or symbols')"
              :required="true"
              class="sf-input--filled"
              :class="$v.name.$error ? 'field-has-error' : ''"
              data-transaction-name="PD - Child - First Name - Field"
            />
          </div>
          <div class="row">
            <MDob
              @dobChange="setDob"
              :dob="dob"
            />
          </div>
          <footer class="m-add-child__actions">
            <template v-if="editMode">
              <div class="row m-add-child__actions-row">
                <SfButton
                  class="sf-button--primary sf-button--full-width"
                  :disabled="!isFormValid"
                >
                  {{ $t('Save') }}
                </SfButton>
              </div>
              <SfLink
                data-transaction-name="PD - Remove child - Button"
                @click.prevent="removeChild"
                class="sf-link--primary"
              >
                <span>{{ $t('Delete child details') }}</span>
              </SfLink>
            </template>
            <template v-else>
              <SfButton
                class="sf-button--primary sf-button--full-width"
                :disabled="!isFormValid"
              >
                {{ $t('Add') }}
              </SfButton>
            </template>
          </footer>
        </form>
      </div>
    </SfOModal>
  </div>
</template>

<script>
import SfOModal from 'theme/components/storefront-override/SfOModal';
import {
  SfInput,
  SfButton, SfLink
} from '@storefront-ui/vue';
import MTags from 'theme/components/molecules/m-tags';
import MDob from 'theme/components/molecules/m-dob';
import { isNameValid } from 'theme/helpers/validation';
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'MModalAddChild',
  components: {
    SfLink,
    SfOModal,
    SfInput,
    SfButton,
    MTags,
    MDob
  },
  data () {
    return {
      selectedGender: 1,
      genderTags: [
        {
          name: this.$t('Boy'),
          value: 1
        },
        {
          name: this.$t('Girl'),
          value: 2
        }
      ],
      name: '',
      dob: null
    }
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
      required: true
    },
    modalData: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },
  validations: {
    name: {
      isNameValid,
      required
    }
  },
  methods: {
    closeModal () {
      this.$emit('close', this.modalData.name);
    },
    selectGender (index) {
      this.selectedGender = this.genderTags[index].value
    },
    setDob (date) {
      this.dob = date;
    },
    prepareChildData () {
      return {
        name: this.name,
        dob: this.dob,
        gender: this.selectedGender
      }
    },
    saveForm () {
      const child = this.prepareChildData()

      if (this.editMode) {
        this.$bus.$emit('edit-child', { child, index: this.index });
      } else {
        this.$bus.$emit('add-child', child);
      }

      this.closeModal();
    },
    removeChild () {
      this.$bus.$emit('remove-child', this.index);
      this.closeModal();
    },
    initData () {
      const { payload } = this.modalData;

      this.selectedGender = payload.child?.gender || 1;
      this.name = payload.child?.name || '';
      this.dob = payload.child?.dob || null;
    }
  },
  computed: {
    isFormValid () {
      return !this.$v.name.$error && this.dob;
    },
    title () {
      return this.editMode ? this.$t('Edit child') : this.$t('Add child');
    },
    editMode () {
      return this.modalData.payload?.child;
    },
    index () {
      return this.modalData.payload?.index;
    },
    getSelectedGenderIndex () {
      return this.genderTags.findIndex(tag => tag.value === this.selectedGender);
    }
  },
  watch: {
    isVisible (val) {
      if (val) this.initData()
    }
  },
  beforeMount () {
    this.initData()
  }
}
</script>

<style lang="scss" scoped>
@import "~theme/css/px2rem";
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.m-add-child {
  &__title {
    font-size: px2rem(24);
    font-weight: var(--font-medium);
    margin-bottom: px2rem(20);
    color: var(--black);
    line-height: 1;
    text-align: center;
  }
}

.row {
  margin: 0 0 10px;
}

.m-add-child__actions {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.m-add-child__actions-row {
  width: 100%;
}

::v-deep {
  .sf-input__wrapper {
    margin-bottom: 0;
  }

  .sf-input__error-message:empty {
    display: none;
  }

  .m-date-picker {
    position: relative;
  }
}
</style>
