<template>
  <div class="m-check-sms check-code">
    <p class="check-code__summary">
      {{ $t('SMS with code sent to number') }}
    </p>
    <div class="check-code__phone-block">
      <div class="phone">
        {{ phone }}
      </div>
      <div @click="edit" v-show="!isLoggedIn" class="edit">
        {{ $t('Edit') }}
      </div>
    </div>
    <div class="check-code__enter-code">
      {{ $t('SMS code') }}
    </div>
    <div v-if="isLoading" class="check-code__pin-loader">
      <SfLoader :loading="isLoading" />
    </div>
    <no-ssr v-else>
      <CodeInput
        :loading="false"
        :field-width="81"
        :fields="4"
        class="check-code pin-code"
        @complete="onCompleteSMS"
        :class="{ 'wrong-code-input': isCodeWrong }"
      />
    </no-ssr>
    <div v-if="isCodeWrong && !anotherError" class="wrong-code">
      {{ $t('Wrong code') }}
    </div>
    <div v-if="anotherError" class="wrong-code">
      {{ anotherError }}
    </div>
    <ASmoothReflow class="send-sms-wrap">
      <div
        v-if="canSendSms"
        @click="sendSmsAction"
        class="check-code__send-again"
      >
        {{ $t('Send code again') }}
      </div>
      <div
        v-else
        class="timer"
      >
        <div v-if="currentLocale !== 'ru-RU' && currentLocale !== 'uk-UA'">
          {{ $t('You can send SMS again after {seconds} seconds', { seconds: waitSmsSeconds }) }}
        </div>
        <div v-else>
          {{ $tc('You_can_send_SMS_again_after_seconds', waitSmsSeconds, { count: waitSmsSeconds }) }}
        </div>
      </div>
    </ASmoothReflow>
  </div>
</template>

<script>
import { SfLoader } from '@storefront-ui/vue'
import { mask } from 'vue-the-mask'
import { mapActions, mapState } from 'vuex'
import { getCleanedPhone } from 'theme/helpers/text'
import NoSSR from 'vue-no-ssr'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import ASmoothReflow from 'theme/components/atoms/a-smooth-reflow.vue';

export default {
  name: 'MCheckSms',
  components: {
    ASmoothReflow,
    CodeInput: () => process.browser ? import('theme/components/molecules/m-pin-input') : null,
    SfLoader,
    'no-ssr': NoSSR
  },
  directives: {
    mask
  },
  props: {
    phone: {
      type: String,
      default: ''
    },
    customSendSmsAction: {
      type: Boolean,
      default: false
    },
    isCodeWrong: {
      type: Boolean,
      default: false
    },
    anotherError: {
      type: String,
      default: () => ('')
    }
  },
  data () {
    return {
      isLoading: false,
      smsCode: '',
      currentLocale: 'uk-UA'
    }
  },
  mounted () {
    this.currentLocale = currentStoreView().i18n.defaultLocale
    this.waitBeforeSendSms()
  },
  computed: {
    ...mapState({
      waitSmsSeconds: state => state.ui.waitSmsSeconds,
      canSendSms: state => state.ui.canSendSms
    }),
    cleanedPhone () {
      return getCleanedPhone(this.phone)
    },
    isLoggedIn () {
      return this.$store.getters['user/isLoggedIn']
    }
  },
  methods: {
    ...mapActions({
      sendOtp: 'userInfo/sendOtp',
      spawnNotification: 'notification/spawnNotification'
    }),
    sendSmsAction () {
      if (this.customSendSmsAction) {
        this.customSendSms()
      } else {
        this.sendSms()
      }
      this.waitBeforeSendSms()
    },
    async sendSms () {
      this.isLoading = true

      try {
        const response = await this.sendOtp(this.cleanedPhone)
        this.isLoading = false

        if (response.result?.status !== 'success') {
          this.onFailure({ result: this.$t('Unexpected error when sending a code by SMS') });
        }
      } catch (error) {
        this.isLoading = false
        this.onFailure({ result: this.$t('Unexpected error when sending a code by SMS') });
      }
    },
    customSendSms () {
      this.$emit('onCustomSendSms')
    },
    onCompleteSMS (value) {
      this.$emit('onCompleteSMS', value)
    },
    edit () {
      this.$emit('onEditPhone')
    },
    onSuccess (message) {
      this.spawnNotification({
        type: 'success',
        message: message,
        action1: { label: this.$t('OK') }
      });
    },
    onFailure (result) {
      this.spawnNotification({
        type: 'danger',
        message: this.$t(result.result),
        action1: { label: this.$t('OK') }
      });
    },
    waitBeforeSendSms () {
      EventBus.$emit('wait-before-send-sms')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/third-party-components/vue-verification-code-input";
@import "~theme/css/px2rem";

.check-code {
  &.pin-code {
    display: flex;
    justify-content: center;
    align-items: center;

    ::v-deep .react-code-input {
      max-width: 355px;
    }
  }

  &__summary {
    font-size: var(--font-sm);
    line-height: var(--font-size-17);
    text-align: center;
    color: var(--dark-gray);
    margin: 0 0 var(--spacer-12);

    @media (max-width: $mobile-max) {
      text-align: left;
    }
  }

  &__phone-block {
    display: flex;
    font-size: var(--font-sm);
    line-height: var(--font-size-17);
    margin: var(--spacer-16) 0 var(--spacer-30);
    justify-content: center;

    div:first-child {
      font-weight: var(--font-medium);
    }

    div:last-child {
      cursor: pointer;
      color: var(--orange)
    }

    @media (max-width: $mobile-max) {
      justify-content: space-between;
      margin: var(--spacer-5) 0 var(--spacer-30);
    }
  }

  &__enter-code {
    margin-bottom: var(--spacer-14);
    text-align: center;

    @media (max-width: $mobile-max) {
      text-align: left;
    }
  }

  &__send-again {
    text-align: center;
    cursor: pointer;
    color: var(--orange);

    @media (max-width: $mobile-max) {
      text-align: left;
    }
  }

  &__pin-loader {
    padding: 44px 0;
  }
}

.timer {
  color: var(--dark-gray);
  font-size: var(--font-size-13);
  text-align: center;
  max-width: 255px;
  margin: 0 auto;

  @media (max-width: $mobile-max) {
    margin: 0;
    max-width: 100%;

    div:first-child {
      text-align: left;
    }
  }
}

.wrong-code {
  max-width: px2rem(355);
  margin: px2rem(-15) auto 0;
  color: var(--red-main);
}
</style>
