<template>
  <div class="m-cart-to-group-wishlist">
    <SfModal
      :visible="isVisible"
      @close="closeModal"
      class="sf-modal--base sf-modal--md sf-modal--mq"
      :title="$t(title)"
    >
      <template #close>
        <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="30" height="30" rx="5" fill="#F3F4F6" />
          <path d="M9 21 21 9l.7.7-12 12L9 21Z" fill="#333" />
          <path d="M21 21.7 9 9.7l.7-.7 12 12-.7.7Z" fill="#333" />
        </svg>
      </template>
      <div class="modal-content">
        <SfHeading
          :title="$t(title)"
          :level="3"
          class="m-cart-to-group-wishlist__title desktop-only"
        />
        <form
          v-if="!isModeCreateNewGroup && !getIsModeCreateNewGroup"
          @submit.prevent="saveCartToGroup"
          class="wishlist-groups-form"
        >
          <div class="wishlist-groups-form__content">
            <SfCheckbox
              v-for="group in wishlistGroups"
              :key="group.id"
              :label="group.name + ' (' + group.items.length + ')'"
              @change="selectWishlistGroup(group.id)"
              :selected="selectedGroup === group.id"
              class="wishlist-groups-form__checkbox"
            />
            <div
              class="wishlist-groups-form-new-group"
              @click="isModeCreateNewGroup = !isModeCreateNewGroup"
            >
              <span class="wishlist-groups-form-new-group__icon">
                <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle opacity=".1" cx="12" cy="12" r="12" fill="#EB6747" />
                  <path d="M12 7v5m0 0H7m5 0v5m0-5h5" stroke="#EB6747" />
                </svg>
              </span>
              <span class="wishlist-groups-form-new-group__title">
                {{ $t('Create new list') }}
              </span>
            </div>
          </div>
          <SfButton
            class="sf-button--full-width wishlist-groups-form__submit"
            type="submit"
            :disabled="!isValidGroupListForm"
          >
            {{ $t('Save') }}
          </SfButton>
        </form>
        <form
          v-else
          @submit.prevent="createNewGroup"
          class="wishlist-groups-form"
        >
          <div class="wishlist-groups-form__content">
            <SfInput
              :label="$t('List name')"
              v-model.trim="newGroupName"
              class="sf-input--filled"
              data-transaction-name="PD - Last Name - Field"
            />
          </div>
          <SfButton
            class="sf-button--full-width wishlist-groups-form__submit"
            type="submit"
            :disabled="!isValidGroupNewForm"
          >
            {{ $t('Create') }}
          </SfButton>
        </form>
      </div>
      <ALoadingSpinner :is-absolute-position="true" v-show="isShowSpinner" />
    </SfModal>
  </div>
</template>

<script>
import { SfHeading, SfModal, SfButton, SfCheckbox } from '@storefront-ui/vue'
import { mapGetters } from 'vuex'
import ALoadingSpinner from 'theme/components/atoms/a-loading-spinner'
import SfInput from 'theme/components/storefront-override/SfInput'

export default {
  name: 'MCartToGroupWishlist',
  components: {
    SfModal,
    SfHeading,
    SfButton,
    SfInput,
    SfCheckbox,
    ALoadingSpinner
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
      required: true
    },
    modalData: {
      type: Object,
      default: () => ({}),
      required: true
    },
    items: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      isModeCreateNewGroup: false,
      selectedGroup: null,
      newGroupName: '',
      isShowSpinner: false
    }
  },
  computed: {
    ...mapGetters({
      wishlistGroups: 'wishlist/getGroups',
      getIsModeCreateNewGroup: 'wishlist/getIsModeCreateNewGroup',
      productsInCart: 'cart/getCartItems'
    }),
    title () {
      return this.isModeCreateNewGroup ? 'New list' : 'Save list'
    },
    isValidGroupListForm () {
      return this.selectedGroup
    },
    isValidGroupNewForm () {
      return this.newGroupName && !/[^0-9a-z_\-\sа-яїіёєґ]/i.test(this.newGroupName)
    }
  },
  methods: {
    resetMode () {
      this.newGroupName = ''
      this.selectedGroup = null
      this.isModeCreateNewGroup = false
    },
    closeModal () {
      if (this.isModeCreateNewGroup && !this.getIsModeCreateNewGroup) {
        this.resetMode()
      } else {
        this.$emit('close', this.modalData.name)
      }
    },
    selectWishlistGroup (id) {
      this.selectedGroup = id
    },
    async saveCartToGroup () {
      this.isShowSpinner = true
      await this.$store.dispatch('wishlist/addItemsToGroup', {
        id: this.selectedGroup,
        items: this.items.length ? this.items : this.productsInCart
      })
      this.isShowSpinner = false

      this.$bus.$emit('cart-is-added-to-wishlist-group', this.selectedGroup)

      this.resetMode()
      this.closeModal()
    },
    async createNewGroup () {
      await this.$store.dispatch('wishlist/addGroup', {
        name: this.newGroupName
      })

      this.resetMode()

      if (this.getIsModeCreateNewGroup) {
        this.$store.dispatch('wishlist/setModeCreateNewGroup', false)
        this.closeModal()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.m-cart-to-group-wishlist {
  ::v-deep .sf-bar {
    > div:first-child {
      display: none;
    }
    --bar-font-size: var(--font-size-20);
    --bar-font-line-height: var(--font-xl);
    --bar-font-weight: var(--font-medium);
    --bar-background: var(--white);
    --bar-padding: var(--spacer-20) 17px;
    border-bottom: 1px solid var(--light-gray);
    text-align: left;
    color: var(--black)
  }

  &__title {
    ::v-deep .sf-heading__title--h3 {
      --heading-title-color: var(--black);
      --heading-title-font-weight: var(--font-medium);
      --heading-title-font-size: var(--font-xl);
      --heading-title-font-line-height: var(--font-xl);
    }
  }
}

.wishlist-groups-form {
  &__content {
    position: relative;

    @include for-desktop {
      margin: var(--spacer-20) 0;
      padding: var(--spacer-20) 0;
      border: 1px solid var(--light-gray);
      border-left: none;
      border-right: none;
    }

    ::v-deep {
      .sf-input__wrapper {
        margin: 0;
      }
      .sf-input__error-message {
        display: none;
      }
    }
  }

  &__checkbox {
    --checkbox-label-margin: 0 0 0 var(--spacer-10);
    --checkbox-font-weight: var(--font-normal);
    --checkbox-font-size: var(-font-size-14);
    --checkbox-label-color: var(--black);
    --icon-width: var(--font-size-12);
    --icon-height: var(--font-size-12);

    margin: 10px 0;

    &:first-child {
      margin-top: 0;
    }

    ::v-deep .sf-checkbox__checkmark--is-active {
      --checkbox-background: var(--orange);
    }
  }

  &-new-group {
    position: relative;
    cursor: pointer;

    &__icon {
      position: absolute;
      left: 0;
      top: 2px;
    }

    &__title {
      margin-left: var(--spacer-35);
      color: var(--orange);
      font-size: var(--font-size-14);
      font-weight: var(--font-normal);
    }
  }

  &__submit {
    position: absolute;
    bottom: var(--spacer-20);
    left: var(--spacer-20);
    width: calc(100% - var(--spacer-20) * 2);

    @include for-desktop {
      position: relative;
      width: 100%;
      left: 0;
      bottom: auto;
    }
  }
}
</style>
