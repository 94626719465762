<template>
  <div class="m-register">
    <h2 class="register-header">
      {{ title }}
    </h2>
    <form @submit.prevent="saveData" class="form">
      <p v-if="showFirstName" class="form-text">
        {{ $t('How can we call you?') }}
      </p>
      <SfInput
        v-if="showFirstName"
        v-model.trim="firstName"
        name="first-name"
        :valid="!$v.firstName.$error"
        :error-message="$t('Please use only letters or symbols')"
        :label="$t('First name')"
        class="form__element"
        data-transaction-name="Register - First Name"
        @input="$v.$touch()"
      />
      <p class="form-text">
        {{ $t('Come up with a PIN for quick login') }}
      </p>
      <no-ssr>
        <CodeInput
          :loading="false"
          :field-width="98"
          :fields="4"
          class="pin-code"
          @complete="onComplete"
        />
      </no-ssr>
      <p v-if="showEmail" class="form-text">
        {{ $t('To receive promotional codes, coupons and additional bonuses for purchases, enter your e-mail') }}
      </p>
      <SfInput
        v-if="showEmail"
        v-model="email"
        name="email"
        :label="$t('Your email')"
        :valid="!$v.email.$error"
        :error-message="$t('Please provide valid e-mail address.')"
        class="form__element"
        data-transaction-name="Register - Email"
        @input="$v.$touch()"
      />
      <!--
      <a-switcher class="a-checkbox" name="allowFaceId" v-model="allowFaceId" :label-text="$t('Allow fast authentication using FaceID or TouchID')" />
      -->
      <ACheckboxToggle
        v-model="needToAddBonusCard"
        :title="$t('Add Varus card')"
      />
      <div class="switcher-info">
        {{ $t('Already have a card? Add it and combine your bonus accounts') }}
      </div>
      <ACardNumberInput
        v-show="needToAddBonusCard"
        class="card-number-input"
        v-model="bonusCardNumber"
        @validate="onCardNumberValidate"
        ref="registerCardNumberInput"
      />
      <SfButton
        v-if="isSubmitEnabled"
        class="sf-button--primary sf-button--full-width form__submit"
        data-transaction-name="Register - Save"
      >
        {{ $t('Save') }}
      </SfButton>
      <SfButton
        v-else
        class="sf-button--primary sf-button--full-width form__submit"
        data-transaction-name="Register - Continue"
        @click="$emit('close')"
      >
        {{ $t('Continue') }}
      </SfButton>
    </form>
  </div>
</template>

<script>
import { Logger } from '@vue-storefront/core/lib/logger'
import { email } from 'vuelidate/lib/validators'
import { isNameValid } from 'theme/helpers/validation'
import { SfButton } from '@storefront-ui/vue'
import { ModalList } from 'theme/store/ui/modals'
import { mapActions, mapState } from 'vuex'
import SfInput from 'theme/components/storefront-override/SfInput'
import ACheckboxToggle from 'theme/components/atoms/a-checkbox-toggle';
import ACardNumberInput from 'theme/components/atoms/a-card-number-input.vue'
import BonusCard from 'theme/mixins/BonusCard'
import GoogleTagManager from 'theme/mixins/gtm'
import NoSSR from 'vue-no-ssr'
import { eSputnikEvent } from 'theme/helpers/es';

export default {
  name: 'MRegister',
  components: {
    SfInput,
    SfButton,
    ACheckboxToggle,
    ACardNumberInput,
    CodeInput: () => process.browser ? import('theme/components/molecules/m-pin-input') : null,
    'no-ssr': NoSSR
  },
  mixins: [GoogleTagManager, BonusCard],
  data () {
    return {
      email: '',
      password: '',
      firstName: '',
      allowFaceId: false,
      addVarusCard: false,
      pinCode: ['-', '-', '-', '-'],
      showFirstName: false,
      showEmail: false,
      title: this.$t('User information'),
      needToAddBonusCard: false
    };
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.user.current
    }),
    isSubmitEnabled () {
      return (this.showEmail && this.email) || (this.showFirstName && this.firstName) ||
        (this.password) || this.needToAddBonusCard
    }
  },
  mounted () {
    const user = JSON.parse(JSON.stringify(this.currentUser))
    this.email = user?.email
    this.firstName = user?.firstname
    this.showFirstName = !this.firstName
    this.showEmail = !this.email
    this.$emit('setTitle', this.title)
  },
  methods: {
    ...mapActions({
      spawnNotification: 'notification/spawnNotification',
      createPinCode: 'userInfo/createPinCode',
      setValidStatus: 'userInfo/setValidationStatus',
      closeModal: 'ui/closeModal',
      userUpdate: 'userInfo/update'
    }),
    onComplete (value) {
      this.password = value
    },
    async saveData () {
      this.$v.$touch()
      this.$refs?.registerCardNumberInput?.validate()

      if (this.$v.$invalid || (this.needToAddBonusCard && this.cardNumberValidate.$invalid)) {
        this.spawnNotification({
          type: 'danger',
          message: this.$t('Please fix the validation errors'),
          action1: { label: this.$t('OK') }
        })

        return
      }

      if (this.password) {
        await this.createPin()
      }

      if (this.needToAddBonusCard) {
        await this.addCard(this.bonusCardNumber)
      }

      if ((this.showEmail && this.email) || (this.showFirstName && this.firstName)) {
        this.saveUserData()
      }

      this.closeModal({ name: ModalList.Auth });
    },
    async saveUserData () {
      const userDataToUpdate = {}
      if (this.email) userDataToUpdate.email = this.email
      if (this.firstName) userDataToUpdate.firstname = this.firstName

      try {
        await this.userUpdate({ customer: userDataToUpdate })
        this.gtmEventHandler({
          gtmEvent: 'personal_data',
          parameters: 'personal_data',
          eventMode: 'category'
        })
        eSputnikEvent('CustomerData', this.currentUser);
      } catch (err) {
        Logger.error(err, 'userInfo/update')()
        this.onFailure('Account data has not been updated');
      }
    },
    async createPin () {
      try {
        const response = await this.createPinCode({ pin: this.password })
        if (response.result?.status === 'success') {
          this.setValidStatus('pin')
          this.onSuccess('PIN code is created');
        } else {
          this.onFailure('Unexpected error when PIN-code creating');
        }
      } catch (err) {
        Logger.error(err, 'createPinCode')()
        this.onFailure('Unexpected error when PIN-code creating');
      }
    }
  },
  validations: {
    firstName: {
      isNameValid
    },
    email: {
      email
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/third-party-components/vue-verification-code-input";

.register-header {
  margin-top: 0;
  font-size: var(--font-xl);
  font-weight: var(--font-medium);
  text-align: center;
  line-height: 25px;
  margin-bottom: 20px;

  @media (max-width: $mobile-max) {
    display: none;
  }
}

.auth-summary {
  font-size: var(--font-sm);
  line-height: 17px;
  text-align: center;
  color: var(--dark-gray);

  @media (max-width: $mobile-max) {
    color: var(--black);
  }
  margin: 0;
}

.form-text {
  font-size: var(--font-sm);
  line-height: 17px;
  color: var(--black);
  margin: 0 0 10px;
}

.m-register {
  ::v-deep {
    .sf-input {
      margin: 0;
      &__error-message {
        padding-bottom: var(--spacer-5);
      }
    }
  }
}

.form {
  width: 100%;
  &__element {
    margin: var(--spacer-base) 0;
  }
  &__submit {
    margin: var(--spacer-xl) 0 0 0;
  }
}

.action-button {
  margin: var(--spacer-xl) 0;
}

.switcher-info {
  margin: 2px 0 20px 48px;
  font-size: 13px;
  line-height: 16px;
  color: var(--dark-gray)
}

.form__submit {
  margin-top: 20px;
}

.card-number-input {
  margin-top: var(--spacer-15);
}
</style>
