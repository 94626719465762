import { isServer } from '@vue-storefront/core/helpers'

declare global {
  interface Window {
    eS: any
  }
}

interface Product {
  sku: string,
  price: {
    special: number,
    regular: number
  },
  stock: {
    is_in_stock: boolean
  },
  qty: number,
  sqpp: {
    special_price: number,
    price: number
  }
}

interface CustomerData {
  email?: string,
  firstname?: string,
  lastname?: string,
  id?: string
}

interface CartData {
  productsInCart: Product[],
  currentCartHash: string,
  orderNumber?: string
}

interface ImpressionData {
  products: any[]
}

const EVENT_NAMES = {
  CATEGORY_PAGE: 'CategoryPage',
  CUSTOMER_DATA: 'CustomerData',
  PRODUCT_PAGE: 'ProductPage',
  STATUS_CART: 'StatusCart',
  PURCHASED_ITEMS: 'PurchasedItems',
  PRODUCT_IMPRESSION: 'ProductImpression',
  ADD_TO_WISHLIST: 'AddToWishlist'
}

const createProductPayload = (product: Product, eventName) => {
  return {
    [eventName]: {
      productKey: product.sku,
      price: String(product.sqpp.special_price ? product.sqpp.special_price : product.sqpp.price),
      isInStock: product.stock.is_in_stock ? 1 : 0
    }
  };
};

const createCustomerDataPayload = (CustomerData: CustomerData) => {
  return {
    CustomerData: {
      user_email: CustomerData?.email,
      user_name: `${CustomerData?.firstname} ${CustomerData?.lastname}`,
      user_client_id: CustomerData?.id,
      user_card_id: '',
      user_phone: '',
      user_tags_gender: ''
    }
  }
};

const createCartPayload = (data: CartData, isPurchasedItems = false) => {
  const items = data.productsInCart.map(product => ({
    productKey: product.sku,
    price: String(product.sqpp.special_price ? product.sqpp.special_price : product.sqpp.price),
    quantity: isPurchasedItems ? product.qty : `${product.qty}`,
    currency: 'UAH'
  }));

  return {
    [isPurchasedItems ? 'PurchasedItems' : 'StatusCart']: items,
    GUID: data.currentCartHash,
    ...(isPurchasedItems && { OrderNumber: data.orderNumber })
  };
};

const createImpressionsPayload = (data: ImpressionData) => {
  const impressionsData = data.products
    .filter(e => e.hasOwnProperty('container_type') || e.url.includes('sc_content='))
    .map(e => {
      let containerType = e.container_type;

      if (!containerType) {
        const urlArray = e.url.split('sc_content=');
        containerType = (urlArray[1] || '').replace('"', '');
      }

      return { product_id: e.id, container_type: containerType };
    });

  return {
    ProductImpression: impressionsData
  };
};

export const eSputnikEvent = (eventName: string, data: any = null) => {
  if (isServer) {
    return;
  }

  if (window && window.hasOwnProperty('eS')) {
    let payload;

    switch (eventName) {
      case EVENT_NAMES.CATEGORY_PAGE:
        payload = { CategoryPage: { categoryKey: data } };
        break;
      case EVENT_NAMES.CUSTOMER_DATA:
        payload = createCustomerDataPayload(data);
        break;
      case EVENT_NAMES.PRODUCT_PAGE:
      case EVENT_NAMES.ADD_TO_WISHLIST:
        payload = createProductPayload(data, eventName)
        break;
      case EVENT_NAMES.STATUS_CART:
      case EVENT_NAMES.PURCHASED_ITEMS:
        payload = createCartPayload(data, eventName === EVENT_NAMES.PURCHASED_ITEMS);
        break;
      case EVENT_NAMES.PRODUCT_IMPRESSION:
        payload = createImpressionsPayload(data);
        break;
    }

    if (!payload) {
      window.eS('sendEvent', eventName);
    } else {
      window.eS('sendEvent', eventName, payload);
    }
  }
}
