import { getCleanedPhone } from 'theme/helpers/text';
import rootStore from '@vue-storefront/core/store';
import config from 'config'

export function uniqInAccount (phone = '') {
  const currentUser = rootStore.state.user.current

  return getCleanedPhone(phone) !== currentUser?.phone
}

export function uniqInAdditional (phone = '') {
  const currentUser = rootStore.state.user.current

  return !currentUser?.additional_phones.includes(getCleanedPhone(phone))
}

export function isValidStringCharacter (char = '') {
  return /^[a-z-_ а-яїіёєґ\d]*$/i.test(char)
}

export function isValidPhoneNumber (phone = '') {
  return /^\+380\d{9}$/.test(phone)
}

export function isFirstCharNotZero (value = '') {
  return value === '' ? true : /^[^0]/.test(value)
}

export function isNameValid (name = '') {
  return /^[a-z-_ а-яїіёєґ']*$/i.test(name)
}

export function isNameValidCyrillic (name = '') {
  return /^[- а-яїіёєґ']*$/i.test(name)
}

export function paymentIsValid (payment: any) {
  if (payment.paymentMethod === 'CARD_ONLINE' /* && !isEmpty(payment.paymentMethodAdditional?.card) */) {
    return true
  }

  const payments = Object.keys(config.orders.payment_methods_mapping)
    .filter(k => k !== 'CARD_ONLINE')

  return payments.includes(payment.paymentMethod)
}

export function isReviewStarsValid (stars = 0) {
  return stars !== 0
}
