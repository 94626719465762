<template>
  <div class="m-modal-carts-confirmation">
    <SfModal
      :visible="isVisible"
      class="sf-modal--base sf-modal--md sf-modal--mq"
      :title="$t('There are already items in your cart')"
    >
      <template #modal-bar>
        <SfHeading
          :title="$t('There are already items in your cart')"
          :level="3"
          class="m-modal-carts-confirmation__title"
        />
      </template>
      <div class="modal-content">
        <div class="m-modal-carts-confirmation__content">
          <div class="m-modal-carts-confirmation-basket">
            <div class="m-modal-carts-confirmation-basket__title" @click="toggleActiveBasket('basket1')">
              <span class="m-modal-carts-confirmation-basket__title-text">{{ $t('Cart saved on other account') }}</span>
              <svg
                class="m-modal-carts-confirmation-basket__title-icon"
                :class="{ 'm-modal-carts-confirmation-basket__title-icon--active': isActiveBasket('basket1') }"
                width="8"
                height="4"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8 0H0l4 4 4-4Z" fill="#32343E" />
              </svg>
            </div>
            <div class="m-modal-carts-confirmation-basket__items" v-show="isActiveBasket('basket1')">
              <div
                class="m-modal-carts-confirmation-basket-item"
                v-for="(item, i) in serverItems"
                :key="i + 1"
              >
                <div class="m-modal-carts-confirmation-basket-item__image">
                  <SfImage
                    class="product-image__picture"
                    :src="item.sku | productImagePath(imageSize.width, imageSize.height)"
                    :alt="item.name || item.title"
                    :width="imageSize.width"
                    :height="imageSize.height"
                  />
                </div>
                <div class="m-modal-carts-confirmation-basket-item__title">
                  {{ item.name || item.title }}
                </div>
              </div>
            </div>
          </div>
          <div class="m-modal-carts-confirmation-basket">
            <div class="m-modal-carts-confirmation-basket__title" @click="toggleActiveBasket('basket2')">
              <span class="m-modal-carts-confirmation-basket__title-text">{{ $t('Current Cart') }}</span>
              <svg
                class="m-modal-carts-confirmation-basket__title-icon"
                :class="{ 'm-modal-carts-confirmation-basket__title-icon--active': isActiveBasket('basket2') }"
                width="8"
                height="4"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8 0H0l4 4 4-4Z" fill="#32343E" />
              </svg>
            </div>
            <div class="m-modal-carts-confirmation-basket__items" v-show="isActiveBasket('basket2')">
              <div
                class="m-modal-carts-confirmation-basket-item"
                v-for="(item, i) in clientItems"
                :key="i + 1"
              >
                <div class="m-modal-carts-confirmation-basket-item__image">
                  <SfImage
                    class="product-image__picture"
                    :src="item.sku | productImagePath(imageSize.width, imageSize.height)"
                    :alt="item.name || item.title"
                    :width="imageSize.width"
                    :height="imageSize.height"
                  />
                </div>
                <div class="m-modal-carts-confirmation-basket-item__title">
                  {{ item.name || item.title }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="m-modal-carts-confirmation__actions">
          <SfButton
            class="sf-button"
            @click="handlerMergeBaskets"
          >
            {{ $t('Merge baskets and continue') }}
          </SfButton>
          <SfLink
            @click.prevent="handlerDeleteServerBasket"
          >
            {{ $t('Delete Basket 2') }}
          </SfLink>
        </div>
      </div>
      <ALoadingSpinner :is-absolute-position="true" v-show="isShowSpinner" />
    </SfModal>
  </div>
</template>

<script>
import { SfModal, SfHeading, SfButton, SfLink, SfImage } from '@storefront-ui/vue';
import ALoadingSpinner from 'theme/components/atoms/a-loading-spinner'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'MModalCartsConfirmation',
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    modalData: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },
  components: {
    SfModal,
    SfHeading,
    SfButton,
    SfLink,
    SfImage,
    ALoadingSpinner
  },
  data () {
    return {
      activeBaskets: {
        basket1: true,
        basket2: false
      },
      imageSize: {
        width: 60,
        height: 60
      },
      isShowSpinner: false
    }
  },
  computed: {
    ...mapGetters({
      isUserInCheckout: 'checkout/isUserInCheckout'
    }),
    coupon () {
      return this.modalData.payload?.coupon || ''
    },
    serverItems () {
      return this.modalData.payload?.serverItems || []
    },
    clientItems () {
      return this.modalData.payload?.clientItems || []
    }
  },
  methods: {
    ...mapActions({
      cartConnect: 'cart/connect',
      cartApplyCoupon: 'cart/applyCoupon',
      checkoutSetModifiedAt: 'checkout/setModifiedAt'
    }),
    async closeModal () {
      await this.$emit('close', this.modalData.name)
    },
    async mergeBaskets () {
      await this.cartConnect({
        mergeQty: false,
        isLogin: this.modalData.payload?.isLogin
      })

      if (this.coupon) {
        await this.cartApplyCoupon(this.coupon)
      }
    },
    async handlerMergeBaskets () {
      this.isShowSpinner = true

      if (this.isUserInCheckout) {
        await this.checkoutSetModifiedAt(0)
        await this.mergeBaskets();
        await this.checkoutSetModifiedAt(Date.now())
      } else {
        await this.mergeBaskets();
      }

      this.isShowSpinner = false
      this.$bus.$emit('after-cart-merge')
      await this.closeModal()
    },
    async handlerDeleteServerBasket () {
      this.isShowSpinner = true

      await this.cartConnect({
        forceClientState: true,
        isLogin: this.modalData.payload?.isLogin
      })

      if (this.coupon) {
        await this.cartApplyCoupon(this.coupon)
      }

      this.isShowSpinner = false
      this.$bus.$emit('after-cart-merge')
      await this.closeModal()
    },
    isActiveBasket (basket) {
      return this.activeBaskets[basket]
    },
    toggleActiveBasket (basket) {
      this.activeBaskets[basket] = !this.activeBaskets[basket]
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~theme/css/breakpoints";

.m-modal-carts-confirmation {
  ::v-deep .sf-modal {
    &__content {
      --modal-content-padding: 0 var(--spacer-10) var(--spacer-10);
      margin: 0 var(--spacer-10);

      @include for-desktop {
        margin: 0 var(--spacer-5);
        --modal-content-padding: 0 var(--spacer-40) var(--spacer-35) var(--spacer-40);
      }
    }

    &__close {
      display: none !important;
    }
  }

  &-basket {
    padding: var(--spacer-10) 0;
    border-top: 1px solid var(--gray3);

    &:first-child {
      border-top: none;

      @include for-desktop {
        border-top: 1px solid var(--gray3);
      }
    }

    &__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      &-text {
        font-weight: var(--font-medium);
        font-size: var(--font-size-16);
        line-height: var(--font-size-19);
        color: var(--black);
      }

      &-icon {
        &--active {
          transform: rotate(180deg);
        }
      }
    }

    &-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: var(--spacer-30);
      padding: 0 var(--spacer-10);

      &__image {
        width: 60px;
      }

      &__title {
        font-size: var(--font-size-13);
        font-weight: var(--font-normal);
        color: var(--black);
        margin-left: var(--spacer-20);
      }
    }
  }

  &__title {
    --heading-title-color: var(--black);
    --heading-title-font-weight: var(--font-medium);
    --heading-title-font-size: var(--font-xl);
    --heading-title-font-line-height: var(--font-xl);
    text-align: left;
    padding: var(--spacer-14) var(--spacer-20) var(--spacer-16) ;
    border-bottom: 1px solid var(--gray3);

    @include for-desktop {
      padding: var(--spacer-50) var(--spacer-50) var(--spacer-20);
      border-bottom: none;
    }
  }

  &__actions {
    --button-size: 100%;
    text-align: center;
    margin-top: var(--spacer-20);
    @include for-mobile {
      padding-bottom: 4rem;
    }

    .sf-button {
      --button-font-size: var(--font-size-18);
      height: 56px;
      @include for-mobile {
        white-space: normal;
        padding: 0;
      }

      &:hover {
        background-color: var(--orange-hover);
      }
    }

    .sf-link {
      --link-font-weight: normal;
      --link-font-size: var(--font-size-14);
      --link-font-line-height: var(--font-size-17);
      --link-color: var(--orange);
      margin-top: var(--spacer-20);
      display: inline-block;
    }
  }
}
</style>
