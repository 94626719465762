import { mapActions } from 'vuex'
import { Logger } from '@vue-storefront/core/lib/logger'

export default {
  name: 'BonusCard',
  data () {
    return {
      bonusCardNumber: null,
      isShowLoader: false,
      cardNumberValidate: {}
    }
  },
  methods: {
    ...mapActions({
      addBonusCard: 'bonusCard/addBonusCard'
    }),
    async addCard (cardNumber = null) {
      if (this.cardNumberValidate.$invalid) {
        this.spawnNotification({
          type: 'danger',
          message: this.$t('Please fix the validation errors'),
          action1: { label: this.$t('OK') }
        })
        return
      }

      try {
        this.isShowLoader = true
        const response = await this.addBonusCard({ 'card_number': cardNumber })

        if (response.result?.status === 'success') {
          this.onSuccess(response?.result?.message);
        } else {
          this.onFailure(response?.result?.errorMessage);
        }
      } catch (err) {
        Logger.error(err, 'addBonusCard')()
        this.onFailure({ result: 'Unexpected error when adding BonusCard' });
      } finally {
        this.isShowLoader = false;
      }
    },
    onCardNumberValidate (val) {
      this.cardNumberValidate = val
    },
    onSuccess (message) {
      this.spawnNotification({
        type: 'success',
        message: this.$t(message),
        action1: { label: this.$t('OK') }
      })
    },
    onFailure (message) {
      this.spawnNotification({
        type: 'danger',
        message: this.$t(message),
        action1: { label: this.$t('OK') }
      })
    }
  }
};
