<template>
  <SfModal
    :visible="isVisible"
    @close="closeModal"
    :title="isMobile || isTablet ? $t('Add card number'): ''"
    class="m-modal-bonus-card"
  >
    <div class="modal-content">
      <SfHeading
        v-if="isDesktop"
        class="form-heading"
        :title="$t('Card number')"
        :level="3"
      />
      <ACardNumberInput
        class="card-number-input"
        v-model="bonusCardNumber"
        @validate="onCardNumberValidate"
        :need-validation="false"
      />
      <SfButton
        :disabled="isSubmitDisable"
        data-transaction-name="Bonus Card - Add"
        @click="addCard(bonusCardNumber)"
        class="sf-button--primary sf-button--full-width"
      >
        {{ $t("Add") }}
      </SfButton>
      <ALoadingSpinner
        v-show="isShowLoader"
        key="spinner"
        :is-absolute-position="true"
      />
    </div>
  </SfModal>
</template>

<script>
import DeviceType from 'theme/mixins/DeviceType';
import { SfModal, SfHeading, SfButton } from '@storefront-ui/vue';
import ACardNumberInput from 'theme/components/atoms/a-card-number-input.vue'
import ALoadingSpinner from 'theme/components/atoms/a-loading-spinner';
import BonusCard from 'theme/mixins/BonusCard';
import { mapActions } from 'vuex';

export default {
  name: 'MModalBonusCardAdd',
  components: {
    SfModal,
    SfHeading,
    SfButton,
    ALoadingSpinner,
    ACardNumberInput
  },
  mixins: [DeviceType, BonusCard],
  props: {
    isVisible: {
      type: Boolean,
      default: false,
      required: true
    },
    modalData: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },
  computed: {
    isSubmitDisable () {
      return !this.bonusCardNumber?.length
    }
  },
  methods: {
    ...mapActions({
      spawnNotification: 'notification/spawnNotification'
    }),
    onAddCard () {
      this.addCard()
      this.closeModal()
    },
    closeModal () {
      this.$emit('close', this.modalData.name)
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.m-modal-bonus-card {
  @include for-desktop {
    --modal-width: 530px;
    --modal-content-padding: 50px;
  }
}

.modal-content {
  &-heading {
    margin: -8px auto var(--spacer-15);
  }
}

.card-number-input {
  margin-top: var(--spacer-15);
}

::v-deep .sf-bar {
  border-bottom: 2px solid var(--light-gray);
}
</style>
