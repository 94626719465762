<template>
  <div class="m-group-wishlist-to-cart">
    <SfModal
      :visible="isVisible"
      @close="closeModal"
      class="sf-modal--base sf-modal--md sf-modal--mq"
      :title="$t('Wishlist')"
    >
      <template #close>
        <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="30" height="30" rx="5" fill="#F3F4F6" />
          <path d="M9 21 21 9l.7.7-12 12L9 21Z" fill="#333" />
          <path d="M21 21.7 9 9.7l.7-.7 12 12-.7.7Z" fill="#333" />
        </svg>
      </template>
      <div class="modal-content">
        <SfHeading
          :title="$t('Wishlist')"
          :level="3"
          class="m-group-wishlist-to-cart__title desktop-only"
        />
        <OCartWishlist />
      </div>
    </SfModal>
  </div>
</template>

<script>
import { SfHeading, SfModal } from '@storefront-ui/vue'
import OCartWishlist from 'theme/components/organisms/o-cart-wishlist'

export default {
  name: 'MGroupWishlistToCart',
  components: {
    SfModal,
    SfHeading,
    OCartWishlist
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
      required: true
    },
    modalData: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },
  provide () {
    return {
      isCartType: true
    }
  },
  methods: {
    closeModal () {
      this.$emit('close', this.modalData.name)
    }
  }
}
</script>

<style lang="scss">
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.m-group-wishlist-to-cart {
  ::v-deep {
    .sf-modal--base {
      @media (min-width: $tablet-min) {
        --modal-width: 630px;
      }
    }
  }

  ::v-deep .sf-bar {
    > div:first-child {
      display: none;
    }
    --bar-font-size: var(--font-size-20);
    --bar-font-line-height: var(--font-xl);
    --bar-font-weight: var(--font-medium);
    --bar-background: var(--white);
    --bar-padding: var(--spacer-20) 17px;
    border-bottom: 1px solid var(--light-gray);
    text-align: left;
    color: var(--black)
  }

  &__title {
    ::v-deep .sf-heading__title--h3 {
      --heading-title-color: var(--black);
      --heading-title-font-weight: var(--font-medium);
      --heading-title-font-size: var(--font-xl);
      --heading-title-font-line-height: var(--font-xl);
    }
  }
}
</style>
