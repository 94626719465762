<template>
  <div class="m-cart-to-group-wishlist">
    <SfModal
      :visible="isVisible"
      @close="closeModal"
      class="sf-modal--base sf-modal--md sf-modal--mq"
      :title="$t('New list')"
    >
      <template #close>
        <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="30" height="30" rx="5" fill="#F3F4F6" />
          <path d="M9 21 21 9l.7.7-12 12L9 21Z" fill="#333" />
          <path d="M21 21.7 9 9.7l.7-.7 12 12-.7.7Z" fill="#333" />
        </svg>
      </template>
      <div class="modal-content">
        <SfHeading
          :title="$t('New list')"
          :level="3"
          class="m-cart-to-group-wishlist__title"
        />
        <form
          @submit.prevent="createNewWishlist"
          class="wishlist-groups-form"
        >
          <div class="wishlist-groups-form__content">
            <SfInput
              :label="$t('List name')"
              v-model.trim="newWishlistName"
              class="sf-input--filled"
            />
          </div>
          <SfButton
            class="sf-button--full-width sf-button--primary wishlist-groups-form__submit"
            type="submit"
            :disabled="!isValidNewWishlistName"
          >
            {{ $t('Create') }}
          </SfButton>
        </form>
      </div>
      <ALoadingSpinner
        :is-absolute-position="true"
        v-show="isShowSpinner"
      />
    </SfModal>
  </div>
</template>

<script>
import { SfHeading, SfModal, SfButton } from '@storefront-ui/vue'
import ALoadingSpinner from 'theme/components/atoms/a-loading-spinner'
import SfInput from 'theme/components/storefront-override/SfInput'
import { mapActions } from 'vuex'
import { Logger } from '@vue-storefront/core/lib/logger';

export default {
  name: 'MModalCreateWishlist',
  components: {
    SfModal,
    SfHeading,
    SfButton,
    SfInput,
    ALoadingSpinner
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
      required: true
    },
    modalData: {
      type: Object,
      default: () => ({}),
      required: true
    },
    items: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      newWishlistName: '',
      isShowSpinner: false
    }
  },
  computed: {
    isValidNewWishlistName () {
      return (
        this.newWishlistName &&
        !/[^0-9a-z_\-\sа-яїіёєґ]/i.test(this.newWishlistName)
      )
    }
  },
  methods: {
    ...mapActions({
      createWishlist: 'wishlist/createWishlist'
    }),
    closeModal () {
      this.resetModal()
      this.$emit('close', this.modalData.name)
    },
    async createNewWishlist () {
      this.isShowSpinner = true

      try {
        await this.createWishlist(this.newWishlistName)
      } catch (e) {
        Logger.warn(e, 'wishlist/createWishlist')
      } finally {
        this.isShowSpinner = false
        this.closeModal()
      }
    },
    resetModal () {
      this.newWishlistName = ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.m-cart-to-group-wishlist {
  ::v-deep .sf-bar {
    > div:first-child {
      display: none;
    }
    --bar-font-size: var(--font-size-20);
    --bar-font-line-height: var(--font-xl);
    --bar-font-weight: var(--font-medium);
    --bar-background: var(--white);
    --bar-padding: var(--spacer-20) 17px;
    border-bottom: 1px solid var(--light-gray);
    text-align: left;
    color: var(--black)
  }

  &__title {
    ::v-deep .sf-heading__title--h3 {
      --heading-title-color: var(--black);
      --heading-title-font-weight: var(--font-medium);
      --heading-title-font-size: var(--font-xl);
      --heading-title-font-line-height: var(--font-xl);
    }

    @media (max-width: $mobile-max) {
      display: none;
    }
  }
}

.wishlist-groups-form {
  &__content {
    position: relative;

    @media (min-width: $tablet-min) {
      margin: var(--spacer-20) 0;
      padding: var(--spacer-20) 0;
      border: 1px solid var(--light-gray);
      border-left: none;
      border-right: none;
    }

    ::v-deep {
      .sf-input__wrapper {
        margin: 0;
      }
      .sf-input__error-message {
        display: none;
      }
    }
  }

  &__submit {
    position: absolute;
    bottom: var(--spacer-20);
    left: var(--spacer-20);
    width: calc(100% - var(--spacer-20) * 2);

    @media (min-width: $tablet-min) {
      position: relative;
      width: 100%;
      left: 0;
      bottom: auto;
    }
  }
}
</style>
