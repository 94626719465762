<template>
  <div class="m-modal-spi">
    <SfOModal
      :overlay="true"
      :visible="isVisible"
      :title="$t('Package rules')"
      :transition-modal="`none`"
      :transition-overlay="`none`"
      :is-mobile-bar="false"
      @close="closeModal"
      class="m-modal-spi__modal"
    >
      <div class="modal__content">
        <div v-html="$t('We avoid using nets to transport goods')" />
      </div>
    </SfOModal>
  </div>
</template>

<script>
import SfOModal from 'theme/components/storefront-override/SfOModal.vue';

export default {
  name: 'MShortPackageInfo',
  components: {
    SfOModal
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    modalData: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },
  methods: {
    closeModal () {
      this.$store.dispatch('ui/hideBottomNavigation', false)
      this.$emit('close', this.modalData.name)
    }
  }
}

</script>
<style lang="scss" scoped>
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";

.m-modal-spi {
  ::v-deep {
    .sf-modal__bar {
      border-bottom: 2px solid var(--light-gray);
    }

    .sf-modal__container {
      top: auto;

      @include for-desktop {
        top: var(--modal-top, 0);
      }
    }
  }

  &__modal {
    --modal-close-top: #{px2rem(10)};
  }
}
</style>
