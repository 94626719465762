<template>
  <div
    class="m-wishlist-manager vso-styled-modal"
  >
    <SfOModal
      :transition-modal="transition"
      :visible="isVisible"
      :title="title"
      :is-mobile-bar="false"
      class="sf-modal--base sf-modal--md m-wishlist-manager__modal"
      :class="{ 'm-wishlist-manager__modal--cart': isCart }"
      @close="closeModal"
    >
      <div class="modal-content">
        <form>
          <SfCheckbox
            v-for="wishlist in wishlists"
            :key="wishlist.wishlist_id"
            :label="wishlist.name"
            :value="String(wishlist.wishlist_id)"
            v-model="selectedWishlists"
            class="m-wishlist-manager__checkbox"
          />
          <SfLink
            @click.prevent="onAddNewListSpoilerClick"
            class="m-wishlist-manager__spoiler sf-link--primary"
          >
            <template v-if="isAddingAListHidden">
              <SfIcon
                color="orange"
                size="xs"
                class="m-wishlist-manager__spoiler-icon"
              >
                <svg
                  class="sf-icon-path"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                >
                  <path d="M5 0V5M5 5H0M5 5V10M5 5H10" stroke="#EB6747" />
                </svg>
              </SfIcon>
            </template>
            <template v-else>
              <span class="sf-icon color-orange size-xs m-wishlist-manager__spoiler-icon m-wishlist-manager__spoiler-icon--opened">
                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="2" fill="none">
                  <path stroke="#fff" stroke-width="2" d="M0 1h10" />
                </svg>
              </span>
            </template>
            <span class="m-wishlist-manager__spoiler-icon-label">{{ $t('Add list') }}</span>
          </SfLink>
          <div
            v-show="!isAddingAListHidden"
            class="add-new-list__block"
          >
            <SfInput
              v-model="newWishlistName"
              :label="$t('List name')"
              class="sf-input--filled"
            />
            <SfButton
              :disabled="!isValidNewWishlistName"
              @click.prevent="createNewWishlist"
              class="sf-button--outline"
            >
              {{ $t('Create') }}
            </SfButton>
          </div>
          <SfButton
            @click.prevent="handleFormSave"
            :disabled="isSavingFormDisabled"
            class="m-wishlist-manager__save sf-button--primary sf-button--full-width"
          >
            {{ $t('Save') }}
          </SfButton>
        </form>
      </div>
      <ALoadingSpinner
        :is-absolute-position="true"
        v-show="isShowSpinner"
      />
    </SfOModal>
  </div>
</template>

<script>
import SfOModal from 'theme/components/storefront-override/SfOModal';
import {
  SfCheckbox,
  SfLink,
  SfInput,
  SfButton,
  SfIcon
} from '@storefront-ui/vue';
import { mapActions, mapGetters } from 'vuex';
import ALoadingSpinner from 'theme/components/atoms/a-loading-spinner.vue';
import { Logger } from '@vue-storefront/core/lib/logger';
import { currentStoreView } from '@vue-storefront/core/lib/multistore';
import { getWishlistProductId } from 'theme/store/wishlist/helpers'
import { eSputnikEvent } from 'theme/helpers/es';

export default {
  name: 'MModalWishlistManager',
  components: {
    ALoadingSpinner,
    SfLink,
    SfCheckbox,
    SfOModal,
    SfInput,
    SfButton,
    SfIcon
  },
  data: () => ({
    isAddingAListHidden: true,
    selectedWishlists: [],
    initialWishlistsIds: [],
    newWishlistName: '',
    isShowSpinner: false,
    isCart: false
  }),
  props: {
    isVisible: {
      type: Boolean,
      default: false,
      required: true
    },
    modalData: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },
  mounted () {
    if (!this.isAddFromCart) this.initDefaultData()
  },
  methods: {
    ...mapActions({
      createWishlist: 'wishlist/createWishlist',
      addItemsToWishlist: 'wishlist/addItemsToWishlist',
      addItemsToMultipleWishlists: 'wishlist/addItemsToMultipleWishlists',
      collectItemsFromWishlists: 'wishlist/collectItemsFromWishlists',
      deleteItemsFromWishlist: 'wishlist/deleteItemsFromWishlist'
    }),
    closeModal () {
      this.$emit('close', this.modalData.name);
    },
    onAddNewListSpoilerClick () {
      this.isAddingAListHidden = !this.isAddingAListHidden;
    },
    async createNewWishlist () {
      this.isShowSpinner = true

      try {
        await this.createWishlist(this.newWishlistName)
      } catch (e) {
        Logger.warn(e, 'wishlist-manager/createWishlist')
      } finally {
        this.isShowSpinner = false
      }
    },
    async handleFormSave () {
      this.isShowSpinner = true;

      const createRemovePromises = async () => {
        const IDsOfWishlistsToRemove = this.getIDsOfWishlistsToRemove();
        return IDsOfWishlistsToRemove.map(wishlistId => this.deleteItemsFromWishlist({
          wishlistId: Number(wishlistId),
          items: [getWishlistProductId(this.products[0], wishlistId, this.getWishlists)]
        }));
      };

      const createAddPromise = async () => {
        const IDsOfWishlistsToAdd = this.getIDsOfWishlistsToAdd();
        return this.addItemsToMultipleWishlists({
          items: this.getSKUs(this.products),
          wishlistsIDs: IDsOfWishlistsToAdd.map(Number)
        });
      };

      try {
        const removeFromWishlistPromises = await createRemovePromises();
        const addToWishlistPromise = await createAddPromise();

        await Promise.all([...removeFromWishlistPromises, addToWishlistPromise]);
        await this.collectItemsFromWishlists();
        eSputnikEvent('AddToWishlist', this.products[0])
      } catch (error) {
        Logger.warn(error, 'wishlist-manager/handleFormSave');
      } finally {
        this.isShowSpinner = false;
        this.closeModal();
      }
    },
    getSKUs (products) {
      return products.map(({ sku }) => ({ sku }));
    },
    getDefaultWishlistName () {
      if (this.locale === 'uk-UA') {
        return 'Обране'
      } else if (this.locale === 'ru-RU') {
        return 'Избранное'
      } else {
        return 'Favorites'
      }
    },
    getWishlistsWithCurrentProduct (wishlists, product) {
      return wishlists.filter(wishlist => {
        return wishlist.items.some(item => item.product_id === product.id)
      })
    },
    getWishlistIdsWithCurrentProduct (wishlists, product) {
      return this.getWishlistsWithCurrentProduct(wishlists, product).map(wishlist => String(wishlist.wishlist_id))
    },
    initDefaultData () {
      this.initialWishlistsIds = this.getWishlistIdsWithCurrentProduct(this.getWishlists, this.products[0])
      this.selectedWishlists = this.initialWishlistsIds
    },
    clearData () {
      this.selectedWishlists = []
      this.initialWishlistsIds = []
      this.newWishlistName = ''
    },
    arraysAreEqual (arr1, arr2) {
      if (arr1.length !== arr2.length) {
        return false;
      }

      const sortedArr1 = arr1.slice().sort();
      const sortedArr2 = arr2.slice().sort();

      for (let i = 0; i < sortedArr1.length; i++) {
        if (sortedArr1[i] !== sortedArr2[i]) {
          return false;
        }
      }
      return true;
    },
    getIDsOfWishlistsToRemove () {
      return this.initialWishlistsIds.filter(wishlistId => !this.selectedWishlists.includes(wishlistId))
    },
    getIDsOfWishlistsToAdd () {
      return this.selectedWishlists.filter(wishlistId => !this.initialWishlistsIds.includes(wishlistId))
    }
  },
  computed: {
    ...mapGetters({
      getWishlists: 'wishlist/getWishlists'
    }),
    transition () {
      return this.isAddFromCart ? 'bottom-top' : null
    },
    title () {
      const payloadTitle = this.modalData?.payload?.title;
      return payloadTitle ? this.$t(payloadTitle) : '';
    },
    isValidNewWishlistName () {
      return (
        this.newWishlistName &&
        !/[^0-9a-z_\-\sа-яїіёєґ]/i.test(this.newWishlistName)
      )
    },
    isSavingFormDisabled () {
      return this.arraysAreEqual(this.selectedWishlists, this.initialWishlistsIds);
    },
    wishlistId () {
      return this.modalData?.payload?.wishlistId;
    },
    wishlists () {
      return this.getWishlists.map(wishlist => ({
        ...wishlist,
        name: wishlist.name === 'Default' ? this.getDefaultWishlistName() : wishlist.name
      }));
    },
    locale () {
      return currentStoreView().i18n.defaultLocale;
    },
    products () {
      return this.modalData?.payload?.products;
    },
    isAddFromCart () {
      return this.modalData?.payload?.isAddFromCart;
    }
  },
  watch: {
    isVisible: {
      immediate: true,
      handler: function (isVisible) {
        if (isVisible) {
          this.isCart = this.isAddFromCart
        }

        if (isVisible && !this.isAddFromCart) {
          this.initDefaultData()
          return
        }

        this.clearData()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~theme/css/px2rem";
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/new-modals";

.m-wishlist-manager {
  &__save {
    margin-top: 40px;
  }

  &__modal {
    @media (min-width: $tablet-min) {
      --modal-top: 50%;
      --modal-left: 50%;
      --modal-bottom: none;
      --modal-right: none;
      --modal-transform: translate3d(-50%, -50%, 0);
      --modal-height: auto;
    }

    --modal-close-top: #{px2rem(13)};
    --modal-close-right: var(--spacer-20);

    &--cart {
      --modal-transform: initial;
      --modal-left: calc(100% - var(--modal-width))!important;
      --modal-bottom: 0!important;
      --modal-right: initial!important;
      --modal-top: auto!important;
      --modal-height: auto!important;
      --modal-width: #{px2rem(600)};

      @media (max-width: $mobile-max) {
        --modal-width: 100%;

        .sf-modal__container {
          min-width: auto;
        }
      }
    }
  }

  ::v-deep {
    .sf-modal__container {
      min-width: 400px;
    }

    .bottom-top-leave-active,
    .bottom-top-enter-active {
      transition: .3s;
    }
    .bottom-top-enter {
      margin-bottom: -100%;
      opacity: 0!important;
    }
    .bottom-top-leave-to {
      margin-bottom: -100%;
      opacity: 0!important;
    }
  }

  &__checkbox {
    --icon-width: 12px;
    --icon-height: 12px;
    --checkbox-label-margin: 0 0 0 10px;
    --checkbox-label-color: var(--black);
    --checkbox-font-size: var(--font-sm);
    --checkbox-font-weight: var(--font-normal);
    --checkbox-font-line-height: 1.5rem;
    margin-bottom: px2rem(15);
  }

  &__spoiler {
    display: flex;
    margin-bottom: px2rem(20);
  }

  &__spoiler-icon {
    border-radius: 50%;
    background-color: rgba(#EB6747, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: px2rem(10);
      height: px2rem(10);
    }

    &--opened {
      background-color: var(--black);

      svg {
        width: px2rem(10);
        height: px2rem(2);
      }
    }
  }

  &__spoiler-icon-label {
    margin-left: px2rem(10);
  }
}
.add-new-list__block {
  display: flex;
  flex-direction: column;
  margin: 0 0 px2rem(20);

  ::v-deep {
    .sf-input {
      flex: 1 1 auto;

      &__error-message {
        position: absolute;
      }

      @include for-desktop {
        --input-margin: 0;
      }

      &__wrapper {
        margin: 0;
      }
    }
    .sf-button {
      @include for-desktop {
        margin: 0 0 0 20px;
      }
    }
  }

  @include for-desktop {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
