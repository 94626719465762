<template>
  <div class="m-modal-change-phone">
    <SfOModal
      :visible="isVisible"
      :is-bar-back="true"
      :is-bar-close="false"
      @close="closeModal"
      @back="closeModal"
      :title="$t('Change account')"
    >
      <MChangePhoneAccount />
    </SfOModal>
  </div>
</template>

<script>
import SfOModal from 'theme/components/storefront-override/SfOModal';
import MChangePhoneAccount from 'theme/components/molecules/m-form-change-account';

export default {
  name: 'ChangePhoneOnAccount',
  components: {
    SfOModal,
    MChangePhoneAccount
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    modalData: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },
  methods: {
    closeModal () {
      this.$store.dispatch('ui/hideBottomNavigation', false)
      this.$emit('close', this.modalData.name)
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.m-modal-change-phone {

  ::v-deep {
    .sf-modal {
      --bar-padding: 0 var(--spacer-20);

      @media (min-width: $tablet-min) {
        --modal-width: 530px;
        --modal-top: 50%;
        --modal-left: 50%;
        --modal-bottom: none;
        --modal-right: none;
        --modal-transform: translate3d(-50%, -50%, 0);
        --modal-height: auto;
        --modal-content-padding: var(--spacer-50);
      }
    }

    .sf-bar {
      border-bottom: 2px solid var(--light-gray);

      div:nth-child(2) {
        flex-grow: 1 ;
        margin-left: var(--spacer-15);
      }

      @media (min-width: $tablet-min) {
        display: none;
      }
    }

    .sf-bar__title {
      font-size: var(--font-size-20);
      font-weight: var(--font-medium);
      line-height: var(--font-size-24);
      padding: var(--spacer-16) 0;
    }

    .sf-modal__container {
      @media (max-width: $mobile-max) {
        height: 100%;
        z-index: var(--modal-sf-content-index);
      }
    }

    .sf-modal__close {
      @media (max-width: $mobile-max) {
        display: none;
      }

      @media (min-width: $tablet-min) {
        display: inline-flex!important;
      }
    }

    .sf-modal__content {
      padding: var(--spacer-50);
      font-family: var(--font-family-inter);

      @media (max-width: $mobile-max) {
        height: 100%;
        padding: 0 var(--spacer-10);
      }

      form {
        height: 100%;
        display:flex;
        flex-direction: column;

        .sf-button {
          font-size: var(--font-size-18);
          line-height: var(--font-size-24);
          margin-top: auto;
          margin-bottom: var(--spacer-10);
          width: 100%;
        }
      }

      fieldset {
        border: none;
        margin: 0;
        padding: 0;
        font-family: var(--font-family-secondary);

        @media (max-width: $mobile-max) {
          height: 100%;
        }

        .sf-heading {
          padding: 0;

          &__title {
            font-size: var(--font-xl);
            font-weight: var(--font-medium);
          }
        }

        p {
          font-size: var(--font-size-13);
          color: var(--dark-gray);
        }

        .sf-input {
          padding-top: var(--spacer-sm);
        }
      }
    }

    .sf-input__wrapper {
      margin-bottom: var(--spacer-4);
    }

    .sf-input {
      width: 100%;
      --input-label-font-size: var(--font-size-12);
      --input-label-top: 23%;

      input {
        background: var(--light-gray);
        --input-bar-display: none;
        outline: none;

        &:focus {
          border: 1px solid var(--orange);
          box-sizing: border-box;

          & ~ * {
            --input-label-top: 23%;
            --input-label-color: var(--dark-gray);
            --input-label-font-size: var(--font-size-12);
          }
        }

        &:focus-visible {
          outline: none;
          border: 1px solid var(--orange);
          box-sizing: border-box;
        }
      }

      &__bar {
        position: relative;
        display: var(--input-bar-display, none);
      }

      &__error-message {
        margin-bottom: var(--spacer-4);
      }
    }

    .sf-input__label:after {
      content: '';
    }
  }
}
</style>
