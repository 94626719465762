import { render, staticRenderFns } from "./m-modal-wishlist-manager.vue?vue&type=template&id=07c2d19b&scoped=true"
import script from "./m-modal-wishlist-manager.vue?vue&type=script&lang=js"
export * from "./m-modal-wishlist-manager.vue?vue&type=script&lang=js"
import style0 from "./m-modal-wishlist-manager.vue?vue&type=style&index=0&id=07c2d19b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07c2d19b",
  null
  
)

export default component.exports