<template>
  <SfInput
    v-model="cardNumber"
    name="number"
    type="number"
    :label="$t('Add card number')"
    :valid="!$v.cardNumber.$error"
    :error-message="errorMessage"
    class="sf-input--filled "
    @blur="validate"
  >
    <template #icon>
      <span class="bar-code_icon" />
    </template>
  </SfInput>
</template>

<script>
import { required, numeric } from 'vuelidate/lib/validators';
import SfInput from 'theme/components/storefront-override/SfInput'

export default {
  name: 'ACardNumberInput',
  components: { SfInput },
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    needValidation: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    cardNumber: {
      get () {
        return this.value
      },
      set (val) {
        this.$v.$touch()
        this.$emit('input', val)
      }
    },
    errorMessage () {
      return !this.$v.cardNumber.required
        ? this.$t('Field is required')
        : this.$t('Please use only digits')
    }
  },
  methods: {
    validate () {
      if (!this.needValidation) return

      this.$v.$touch()
      this.$emit('validate', this.$v.cardNumber)
    }
  },
  validations: {
    cardNumber: {
      required,
      numeric
    }
  }
};
</script>
<style lang="scss" scoped>
@import '~@storefront-ui/shared/styles/helpers/breakpoints';
@import "~theme/css/fonts";

.bar-code_icon {
  position: absolute;
  right: 21px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: var(--font-size-24);

  @include font-icon(var(--icon-barcode));
}
</style>
