<template>
  <div class="o-checkout-modal">
    <SfModal
      :visible="isVisible"
      @close="closeModal"
      :title="title"
    >
      <OCheckCode
        :phone="payloadPhone"
        :valid-status="payloadStatus"
        :flag="flag"
        :is-visible="isVisible"
        :client-state-save="clientStateSave"
        @close="closeModal"
      />
    </SfModal>
  </div>
</template>

<script>
import { SfModal } from '@storefront-ui/vue'
import OCheckCode from 'theme/components/organisms/o-check-code'

export default {
  name: 'OModalCheckoutCheckCode',
  components: { SfModal, OCheckCode },
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    modalData: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },
  computed: {
    payloadPhone () {
      return this.modalData?.payload?.phone || ''
    },
    payloadStatus () {
      return this.modalData?.payload?.status || ''
    },
    flag () {
      return this.modalData?.payload?.flag || ''
    },
    clientStateSave () {
      return this.modalData?.payload?.clientStateSave || false
    },
    title () {
      return this.flag === 'pin' ? this.$t('PIN code') : this.$t('SMS code')
    }
  },
  methods: {
    closeModal () {
      this.$emit('close', this.modalData.name)
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
.o-checkout-modal {
  --modal-close-top: var(--spacer-20);
  --modal-close-right: var(--spacer-20);
}
::v-deep {
  .sf-input--has-text, .sf-input input[type="date"] ~ *, .sf-input input[type="time"] ~ *, .sf-input input[type="image"] ~ *, .sf-input input[type="file"] ~ * {
    --input-label-top: 20%;
  }

  .sf-modal__container {
    @media only screen and (min-width: $tablet-min) {
      width: 530px;
    }
  }

  .sf-modal__bar {
    --bar-font-size: var(--font-size-20);
    --bar-font-weight: var(--font-medium);
    --bar-font-line-height: 1.21;
    border-bottom: 2px solid var(--light-gray);

    & > div:first-child {
      display: none;
    }

    @media only screen and (min-width: $tablet-min) {
      border: none;
    }
  }

  .sf-bar__title {

    @media only screen and (min-width: $tablet-min) {
      display: none;
    }
  }

  .sf-modal__content {
    padding: 33px 20px 20px;
    flex-grow: 1;
    @media only screen and (min-width: $tablet-min) {
      padding: 50px 87px;
    }
    font-family: var(--font-family-inter);
    --modal-content-font-weight: var(--font-normal);
  }

  .sf-input {
    width: 100%;
    --input-label-font-size: var(--font-size-12);

    input {
      border: none;
      background: var(--light-gray);
      --input-bar-display: none;

      &:focus {
        & ~ * {
          --input-label-top: 23%;
          --input-label-color: var(--dark-gray);
          --input-label-font-size: var(--font-size-12);
        }
      }
    }

    &__bar {
      position: relative;
      display: var(--input-bar-display, none);
    }
  }

  .sf-input__label:after {
    content: '';
  }
}
</style>
